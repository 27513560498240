import {
  ECoordType,
  getStaticMapUrl as getTopStaticMapUrl,
  TCoordinate,
  TMarker,
} from '@lcc/tmap-static-map';
import {TOP_HOST} from 'constant/Path';

const DEFAULT_ZOOM = 16;
const baseUrl = `${TOP_HOST}/tmapv20/staticMap`;
const appKey = '91d8433b-370a-4093-a4f5-740facda1ddf';

type TProps = {
  center: TCoordinate;
  zoom?: number;
  markers: TMarker[];
  width?: number;
  height?: number;
  coordType?: ECoordType;
};

export const getStaticMapUrl = ({
  center,
  markers,
  width,
  height,
  zoom = DEFAULT_ZOOM,
  coordType = ECoordType.WGS84GEO,
}: TProps) => {
  return getTopStaticMapUrl({
    center,
    zoom,
    markers,
    width,
    height,
    baseUrl,
    appKey,
    coordType,
  });
};
