import {useCallback, useRef} from 'react';
import classNames from 'classnames';
import InView from 'react-intersection-observer';
import {TNaverSAAd} from 'types/Ads';
import {useOnce} from 'hooks/useOnce';
import ImageLoader from 'components/ImageLoader';
import useThrottle from 'hooks/useThrottle';
import ImgAdTagSrc from 'resource/images/AD_Tag.png';

import s from 'styles/modules/NaverKeyword.module.scss';

type TProps = {
  data?: TNaverSAAd;
  onInitAd?: () => void;
  onViewAd?: (url: Nullable<string>) => void;
  onClickAd?: (url: Nullable<string>) => void;
};

const NaverKeyword = ({data, onInitAd, onViewAd, onClickAd}: TProps) => {
  const ableToRun = useThrottle();

  const refViewLogSent = useRef(false);

  const handleChange = useCallback(
    (inView) => {
      if (inView && data && !refViewLogSent.current) {
        refViewLogSent.current = true;
        onViewAd?.(data.displayUrl);
      }
    },
    [data, onViewAd]
  );

  const handleClickAd = useCallback(() => {
    if (!ableToRun()) {
      return;
    }

    if (!data) {
      return;
    }

    onClickAd?.(data.clickUrl);
  }, [ableToRun, data, onClickAd]);

  useOnce(!!data, () => {
    onInitAd?.();
  });

  return (
    <div className={s.keyword_wrap} onClick={handleClickAd}>
      {data ? (
        <InView onChange={handleChange} className={s.contents_wrap} role="link" aria-label="광고">
          <>
            {data.headline && <div className={s.title}>{data.headline}</div>}

            {data.description && <div className={s.description}>{data.description}</div>}

            {data.descriptionExtension?.description && (
              <div className={s.event}>
                <span>{data.descriptionExtension.description}</span>
              </div>
            )}

            {data.displayUrl && (
              <div className={s.url_wrap}>
                <img src={ImgAdTagSrc} width={29} height={18} alt="광고" />
                <span className={s.url}>{data.displayUrl}</span>
              </div>
            )}
          </>
        </InView>
      ) : (
        <div className={s.default_wrap}>
          <div className={classNames(s.default_bar, s.title)} />
          <div className={classNames(s.default_bar, s.description)} />
          <div className={classNames(s.default_bar, s.event)} />
          <div className={classNames(s.default_bar, s.url)} />
        </div>
      )}

      <div className={s.right_wrap} role="presentation" aria-hidden="true">
        {data?.imageExtension?.imageUrl && (
          <div className={s.image_box}>
            <ImageLoader
              src={data.imageExtension.imageUrl}
              alt={'네이버 검색광고 이미지'}
              // onError={() => setImageError(true)}
              toggleClass={s.fade_in}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NaverKeyword;
