import 'styles/index.scss';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import 'core-js/features/array/flat';
import 'core-js/features/object/entries';

import App from './App';
import 'utils/Datadog';

import {store} from 'ducks/store';
import {EReceiverEmitterActions, ReceiverEmitter} from 'utils/tmapInApp';

ReceiverEmitter.on(EReceiverEmitterActions.DISPATCH_STORE, (action) => {
  if (action) {
    store.dispatch(action);
  }
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
);
