import {TTMapAdsLogType, TTMapAdUnitMaterial} from 'hooks/useTMapAds';
import {TApiResponse, TApiStatus} from 'types/Api';

export type TFestivalItem = {
  poiId: number;
  pkey?: number | string;
  centerX?: number;
  centerY?: number;
  wgs84X?: number;
  wgs84Y?: number;
  navSeq?: number | string;
  navX?: number;
  navY?: number;
  name: string;
  imageUrl: Nullable<string>;
  date: Nullable<string>;
  status: string;
  areaDepth1Name: Nullable<string>;
  areaDepth2Name: Nullable<string>;
  goingCount: number;
  tick?: number;
  isRunning?: boolean;
};

export type AdTrackingUrl = {
  key: TTMapAdsLogType;
  value: string;
};

export type TAdvertiseInfo = {
  ad_type: string;
  dsp_type: string;
  start_date: string;
  end_date: string;
  is_top_fixed: boolean;
  is_Landing_url: string;
};

export type TFestivalAdvertiseInfo = {
  poiId: number;
  pkey: string;
  centerX: number;
  centerY: number;
  wgs84X?: number;
  wgs84Y?: number;
  navSeq: string;
  navX: number;
  navY: number;
  name: string;
  imageUrl: Nullable<string>;
  date?: string;
  status: string;
  areaDepth1Name?: string;
  areaDepth2Name?: string;
  goingCount: number;
  tick?: number;
  advertiseTrackingUrls: Array<AdTrackingUrl>;
  advertiseMaterials: Nullable<Array<TTMapAdUnitMaterial>>;
  advertiseInfo: TAdvertiseInfo;
  isRunning?: boolean;
};

// API에서 내려온 데이터셋
export type TFestivalApiResponse = {
  themeInfos: TFestivalItem[];
  currentPage: number;
  totalPage: number;
  totalCount: number;
};

// 실제 스토어에서 운용할 데이터셋
// (다른 리스트와 규격을 맞추기 위해 'festivalInfos'를 'list'로 변환하여 사용)
export type TFestivalData = Omit<TFestivalApiResponse, 'themeInfos'> & {list: TFestivalItem[]};

export type TFestivalAdvertiseApiResponse = {
  festivalInfos: TFestivalAdvertiseInfo[];
};

export enum ESortOption {
  POPULARITY = 'popularity', // 인기순
  DISTANCE = 'distance', // 거리순
  EVENT_START_DATE = 'event_start_date', // 축제 시작일순
  EVENT_HEADING_FOR = 'event_heading_for', // 축제 지금 가는 순
}

export enum EEventHolding {
  NOW = 'now', // 진행중
  BEFORE = 'before', // 진행예정
  SOON = 'soon', // 진행중이거나 D-7 이내
}

export type TFestivalParams = {
  regionDepth1Code?: string;
  page?: number;
  size?: number;
  sort?: ESortOption; // 정렬조건 (기본값: popularity, distance 요청 시 userX, userY 필수)
  lon?: number; // wgs84 X좌표
  lat?: number; // wgs84 Y좌표
  eventHolding?: EEventHolding;
};

export type TFestivalPayload = TApiResponse<TFestivalApiResponse>;
export type TFestivalAdvertisePayload = TApiResponse<TFestivalAdvertiseApiResponse>;

export type TFestivalState = TApiStatus<TFestivalData> & {
  advertise: TApiStatus<TFestivalAdvertiseApiResponse>;
  sort: ESortOption;
  eventHolding?: EEventHolding;
  lastParam?: TFestivalParams;
};
