import {TPersonalPlaceItem, TPersonalPublicRecentItem} from 'ducks/userInfo/types';
import {ERPFlagCode, TSKBesselCoordinate, TWgs84Coordinate} from './App';

export enum ESearchTabs {
  RECENT_EDIT = 'RECENT_EDIT',
  RECENT = 'RECENT',
  ADDRESS = 'ADDRESS',
  MAP = 'MAP',
  RECOMMEND = 'RECOMMEND',
}

export enum ETab {
  HOME = 'home',
  OFFICE = 'office',
  FAVORITE = 'favorite',
}

export enum ERecentItemType {
  POI = 'POI',
  QUERY = 'QUERY',
  PUBLIC_POI = 'PLACE',
  HOME = 'HOME',
  OFFICE = 'OFFICE',
  FAVORITE = 'FAVORITE',
  FAVORITE_STATION = 'FAVORITE_STATION',
  FAVORITE_SUBWAY = 'FAVORITE_SUBWAY',
  BUS_STATION = 'BUSSTATION',
  SUBWAY = 'SUBWAY',
}

export enum EGuideSearchType {
  GENERAL = 'general',
  LOCATED_QUERY = 'located_query',
  LOCATED_BRANCH = 'located_branch',
  DEFAULT = '',
}

export enum ESuggestSearchType {
  GUIDE_SEARCH = 'guide_search',
  REPLACE = 'replace',
  RECOMMEND = 'recommend',
  DEFAULT = '',
}

export type TRecentItem = {
  id: string;
  type: ERecentItemType;
  name: string;
  date: string;
  poiInfo?: TPersonalPlaceItem | TPersonalPublicRecentItem;
  transportId?: string;
};

export type TRecentAppItem = {
  pkey: Nullable<string>;
  poiId: Nullable<string>;
  navSeq: Nullable<string>;
  name: string;
  orgName: string; // ios에만 존재
  centerX: string;
  centerY: string;
};

export enum EFavoriteType {
  HOME = 'HOME',
  OFFICE = 'OFFICE',
  POI = 'POI',
  ROUTE = 'ROUTE',
  PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT',
}

// TPersonalPlaceItem 과 형태는 동일한데 Nullble 체크등을 위해 설정
export type TFavoriteItem = {
  pkey: Nullable<string>;
  poiId: Nullable<string>;
  navSeq: Nullable<string>;
  customName: string;
  rpFlag: number;
  addInfo: Nullable<string>;
} & TSKBesselCoordinate &
  TWgs84Coordinate & {
    type: EFavoriteType;
  };

export type TFavoriteAppItem = {
  pkey: Nullable<string>;
  poiId: Nullable<string>;
  navSeq: Nullable<string>;
  poiName: string;
  address: string;
  rpFlag: number;
  tel: Nullable<string>;
} & TSKBesselCoordinate;

export type TRoutePoint = {
  pkey: Nullable<string>;
  poiId: Nullable<string>;
  navSeq: Nullable<string>;
  placeName: string;
  navX: number;
  navY: number;
  wgs84NavX?: number;
  wgs84NavY?: number;
};

export type TFavoriteRouteItem = {
  customTitle: Nullable<string>;
  departure: TRoutePoint;
  destination: TRoutePoint;
  routeTrace: string;
};

export enum EAddressMode {
  CATE1 = '1',
  CATE2 = '2',
  CATE3 = '3',
  DETAIL = 'DETAIL',
}

export type TAddressItem = {
  areaId: string;
  areaName: string;
  areaDepth1Code: string;
  areaDepth2Code: string;
  areaDepth3Code: string;
};

export type TSuggestQuery = {
  keyword: string;
  coordinates: string;
  rpFlag: string;
};

export type TSuggestPoi = {
  poi_id: number;
  pkey: number;
  keyword: string;
  full_address: string;
  full_address_jibun: string;
  cate_name: string;
  cate_nick_name: string;
  coordinates: {
    lon: string;
    lat: string;
  };
  nav_coordinates: {
    lon: string;
    lat: string;
  };
  center_x: string;
  center_y: string;
  nav_x: string;
  nav_y: string;
  distance: number;
  rp_flag: ERPFlagCode; // 'G'
};

export type TSuggestResponse = {
  returnCode: number;
  totalTime: number;
  totalCount: number;
  v1_suggestions: {
    totalCount: number;
    suggestions: TSuggestQuery[];
  };
  v2_suggestions: {
    totalCount: number;
    suggestions: TSuggestPoi[];
  };
};

export enum ESortOption {
  SCORE = 'score',
  SCORE_WEEK = 'score_week',
  DISTANCE = 'distance',
  PRICE = 'price',
  DEST_SCORE = 'dest_nearby_score',
}

export enum ESearchLocationType {
  USER = 'user_real',
  MAP = 'map',
  USER_ON_MAP = 'user_real_onmap',
  MAP_ON_MAP = 'map_onmap',
}

export type TBusClass = {
  code: string;
  name: string;
};

// https://tmobi.atlassian.net/wiki/spaces/TARDIS/pages/314574207/01.+App+citycode
export enum ECityCode {
  SEOUL = 11,
  BUSAN = 26,
  DAEGU = 27,
  INCHEON = 28,
  GWANGJU = 29,
  DAEJEON = 30,
  ULSAN = 31,
  SEJONG = 36,
  GYEONGGI = 41,
  GANGWON = 42,
  CHUNGBUK = 43,
  CHUNGGNAM = 44,
  JEONBUK = 45,
  JEONNAM = 46,
  GYUNGBUK = 47,
  GYUNGNAM = 48,
  JEJU = 50,
}

export enum EBusType {
  NORMAL = 1, // 일반
  SEAT = 2, // 좌석
  TOWN = 3, // 마을
  DIRECT = 4, // 직행
  AIRPORT = 5, // 공항
  GANSUN_EXPRESS = 6, // 간선 급행
  OUTSIDE = 10, // 외곽
  GANSUN = 11, // 간선
  JISUN = 12, /// 지선
  CIRCULAR = 13, // 순환
  WIDE_AREA = 14, // 광역
  EXPRESS = 15, // 급행
  TOUR = 16, // 투어
  RURAL = 20, // 농어촌
  INTERCITY = 22, // 시외
  EXPRESS_GANSUN = 26, // 급행 간선
  WIDE_AREA_EXPRESS = 28, // 광역 급행
}
