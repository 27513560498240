import {useLocation} from 'react-router-dom';

// react-router-dom useMatch 배열받을 수 있도록
export const useMatchPaths = (paths) => {
  const location = useLocation();
  const currentPath = location.pathname;

  return paths.some((path) => {
    if (path === currentPath) {
      return true;
    }

    // 와일드카드 경로 처리 (예: '/path/*')
    if (path.endsWith('/*')) {
      const basePath = path.slice(0, -2);
      return currentPath.startsWith(basePath);
    }

    return false;
  });
};
