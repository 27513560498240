import {useCallback, useEffect, useRef, useState} from 'react';
import TMapSender from '@lcc/tmap-inapp';
import {HybridBridge} from 'utils/searchBar';

import {EHistoryStack} from 'ducks/userInteraction/types';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';

import useDimmedQuery from 'hooks/useDimmedQuery';
import {useOnce} from 'hooks/useOnce';
import useStartWhenTrue from 'hooks/useStartWhenTrue';

import {sendSearchClickLog} from 'utils/logManager';

import ModalPopup from 'components/ModalPopup';

import styles from 'styles/components/RouteAddPopup.module.scss';
import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';
import useMoveToTarget from 'hooks/useMoveToTarget';

//https://tmobi.atlassian.net/browse/CLIENTQA-6291 검색창 focus 이후 타이밍 맞춰서 키보드 숨기기
const HIDE_KEYBOARD_SEC = 1500;

const DestinationButton = ({onComplete}) => {
  const [count, setCount] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((c) => {
        if (c < 1) {
          clearInterval(timer);
          return 0;
        }
        return c - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useOnce(count === 0, () => {
    onComplete();
  });

  return (
    <span className={styles.count}>
      목적지로 <b>{count}</b>
    </span>
  );
};

const RouteAddPopup = () => {
  const routePopupModal = useDimmedQuery({id: 'ROUTE_POPUP'});
  const dispatch = useAppDispatch();
  const [isCountButton, setIsCountButton] = useState(true);
  const refIsOpen = useRef(false);
  const refRotate = useRef<NodeJS.Timeout>();

  const {isLandscape, isHybrid, drivingPoiDataJson} = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
    drivingPoiDataJson: state.userInteraction.drivingPoiDataJson,
    isLandscape: state.layout.appSize.isLandscape,
  }));
  const {map} = useVSMInterfaceConsumer();
  const {reqMode} = useMoveToTarget();

  const close = useCallback(() => {
    routePopupModal.close();
  }, [routePopupModal]);

  const toVia = useCallback(() => {
    sendSearchClickLog('popup_tap.setthrough');
    drivingPoiDataJson && TMapSender.setViaOfRoute(drivingPoiDataJson);
    close();
  }, [drivingPoiDataJson, close]);

  const toDestination = useCallback(() => {
    sendSearchClickLog('popup_tap.setdestination');
    drivingPoiDataJson && TMapSender.setDestinationOfRoute(drivingPoiDataJson, reqMode);
    close();
  }, [drivingPoiDataJson, close, reqMode]);

  const handleCompleteTimer = useCallback(() => {
    toDestination();
  }, [toDestination]);

  const handleClickWrap = useCallback(() => {
    setIsCountButton(false);
  }, []);

  useEffect(() => {
    refIsOpen.current = routePopupModal.isOpen;

    if (routePopupModal.isOpen) {
      setTimeout(() => {
        TMapSender.showSoftKeyboard(false);
      }, HIDE_KEYBOARD_SEC);
    }
  }, [routePopupModal.isOpen]);

  useEffect(() => {
    if (refIsOpen.current) {
      refRotate.current && clearTimeout(refRotate.current);
      refRotate.current = setTimeout(() => {
        map?.resize();
      }, 100);
    }
  }, [isLandscape]);

  useEffect(() => {
    if (!!drivingPoiDataJson) {
      routePopupModal.open();
    }
  }, [!!drivingPoiDataJson]);

  useEffect(() => {
    if (!routePopupModal.isOpen) {
      dispatch(actions.userInteraction.setDrivingPoiDataJson());
      setIsCountButton(true);
    }
  }, [routePopupModal.isOpen]);

  const handleChangeToTrue = useCallback(() => {
    dispatch(actions.userInteraction.pushHistoryStack(EHistoryStack.ROUTE_ADD_POPUP));
    isHybrid && HybridBridge.showModal();
  }, [dispatch, isHybrid]);

  const handleChangeToFalse = useCallback(() => {
    dispatch(actions.userInteraction.popHistoryStack());
    isHybrid && HybridBridge.hideModal();
  }, [dispatch, isHybrid]);

  useStartWhenTrue(routePopupModal.isOpen, handleChangeToTrue, handleChangeToFalse);

  if (!routePopupModal.isOpen) {
    return null;
  }

  return (
    <ModalPopup
      title="경유지 혹은 목적지로 설정하시겠습니까?"
      onClickWrap={handleClickWrap}
      detailComponent={
        <div>
          새로운 경로가 설정되면
          <br />
          이전 안내는 자동으로 종료됩니다.
        </div>
      }
      buttons={[
        {
          children: '경유지로',
          onClick: toVia,
        },
        {
          type: 'confirm',
          children: isCountButton ? (
            <DestinationButton onComplete={handleCompleteTimer} />
          ) : (
            '목적지로'
          ),
          onClick: toDestination,
        },
      ]}
      onClickClose={() => {
        sendSearchClickLog('popup_tap.setdestination_close');
        routePopupModal.close();
      }}
    />
  );
};

export default RouteAddPopup;
