import {ReactNode, useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import TMapSender from '@lcc/tmap-inapp';
import InView from 'react-intersection-observer';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {fetchSearchMore} from 'ducks/search/slice';
import {ESearchCollectionType} from 'ducks/search/types';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import useLogger from 'hooks/useLogger';
import useSearchResult from 'hooks/useSearchResult';
import ErrorReload from 'components/ErrorReload';
import NoResult from 'components/NoResult';
import SearchResultPoiList from 'components/search/SearchResultPoiList';
import SearchResultBusLineList from 'components/search/SearchResultBusLineList';
import SearchResultBusStationList from 'components/search/SearchResultBusStationList';
import SearchResultAdBanner from 'components/search/SearchResultAdBanner';
import {EAppRequestMode} from 'types/App';
import HybridBridge from 'utils/searchBar';
import {EAdCode} from 'constant/Ads';

import {ReactComponent as IcoNoticeSolid} from 'resource/images/@tmds_solid/ico_notice_solid.svg';
import {ReactComponent as IcoArrowRightBold} from 'resource/images/@tmds_element/ico_arrow_right_bold.svg';

import s from 'styles/components/search/SearchResultList.module.scss';

type TProps = {
  onReload?: () => void;
};

const LIST_COMPONENT_MAP: Record<ESearchCollectionType, (p) => ReactNode> = {
  [ESearchCollectionType.POI]: (p) => <SearchResultPoiList {...p} />,
  [ESearchCollectionType.BUS_LINE]: (p) => <SearchResultBusLineList {...p} />,
  [ESearchCollectionType.BUS_STATION]: (p) => <SearchResultBusStationList {...p} />,
};

const SearchResultList = ({onReload}: TProps) => {
  const dispatch = useAppDispatch();
  const {queries} = useParseQueryLocation();
  const navigate = useNavigate();

  const {collectionType} = useAppSelector((state) => ({
    collectionType: state.search.data.collectionType,
  }));

  const needRegisterNewPoi = collectionType === ESearchCollectionType.POI;
  const isEventTab = useMemo(
    () => queries.reqMode === EAppRequestMode.EVENT_PAGE,
    [queries.reqMode]
  );

  const {sendClickLogWithMapView} = useLogger();

  const {searchList, isError, isEmpty, isLoading, ...visibleStatus} = useSearchResult();

  const handleShowMore = useCallback(
    (isVisible) => {
      isVisible && !isLoading && dispatch(fetchSearchMore());
    },
    [dispatch, isLoading]
  );

  if (isError) {
    return <ErrorReload onReload={onReload} />;
  }

  if (isEmpty) {
    return (
      <>
        {collectionType === ESearchCollectionType.POI && (
          <SearchResultAdBanner adCode={EAdCode.SEARCH_RESULT_CS} />
        )}
        <NoResult
          top={needRegisterNewPoi ? 60 : 100}
          title={'검색 결과가 없습니다.'}
          description={
            isEventTab ? (
              <div className={s.event_desc_wrap}>
                <div>
                  참여할 음식점, 카페, 술집을 검색 후
                  <br />
                  <span className={s.bold}>목록에서 [확인] 버튼</span>을 눌러주세요.
                </div>
                <div>
                  일부 맛집의 경우 선택이 불가할 수 있어요.
                  <br />
                  (프렌차이즈 포함)
                </div>
              </div>
            ) : needRegisterNewPoi ? (
              '혹시 찾으시는 장소가 없나요?'
            ) : undefined
          }
          buttonProps={
            isEventTab
              ? {
                  text: '다른 장소 검색',
                  onClick: () => {
                    navigate(-1);
                    HybridBridge.moveToMain();
                  },
                  type: 'default',
                }
              : needRegisterNewPoi
                ? {
                    text: '새장소 등록',
                    onClick: () => {
                      sendClickLogWithMapView('tap.addpoi');
                      TMapSender.registerNewPoi(queries.searchQuery);
                    },
                  }
                : undefined
          }
        />
      </>
    );
  }

  return (
    <div>
      <ul className={s.wrap}>
        {LIST_COMPONENT_MAP[collectionType]?.({
          list: searchList,
          searchQuery: queries.searchQuery,
        })}
      </ul>
      {visibleStatus.isShowMoreChecker && (
        <InView
          onChange={handleShowMore}
          threshold={0.01}
          rootMargin="100px 0px 0px 0px"
          className={s.more_checker}
        />
      )}
      {searchList.length > 0 &&
        visibleStatus.isLastPage &&
        collectionType === ESearchCollectionType.POI && (
          <div className={s.regist_new_place}>
            <button
              className={s.btn_regist}
              onClick={() => {
                sendClickLogWithMapView(
                  'tap.addpoi',
                  {
                    search_query: queries.searchQuery,
                  },
                  {
                    includeTicketId: true,
                  }
                );
                TMapSender.registerNewPoi(queries.searchQuery);
              }}
            >
              <i className={s.notice}>
                <IcoNoticeSolid />
              </i>
              <span className={s.text}>혹시 찾으시는 장소가 없다면 등록해주세요</span>
              <i className={s.landing}>
                <IcoArrowRightBold />
              </i>
            </button>
          </div>
        )}
    </div>
  );
};

export default SearchResultList;
