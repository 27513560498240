import s from 'styles/components/BuildInfo.module.scss';
import TMapSender from '@lcc/tmap-inapp';
import {useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import LocalStorage from '@lcc/web-storage';

const DEV_ORIGIN_INPUT = 'DEV_ORIGIN_INPUT';

export const BuildInfoOriginInput = () => {
  const location = useLocation();
  const [origin, setValue] = useState(LocalStorage.getItem(DEV_ORIGIN_INPUT) || '');
  const path = useMemo(() => `${location.pathname}${location.search}`, [location]);
  const target = useMemo(() => origin + path, [origin, path]);

  return (
    <div>
      <p className={s.origin_input}>
        <input value={origin} onChange={(e) => setValue(() => e.target.value)} />
        <span
          onClick={(e) => {
            TMapSender.copyClipboard('경로', target);
            TMapSender.makeToast(`${target} 경로가 복사되었습니다.`);
          }}
        >
          {path}
        </span>
      </p>
      <p className={s.btn_wrap}>
        <button
          onClick={() => {
            if (!origin) {
              TMapSender.makeToast('input 값을 확인해주세요.');
            } else {
              LocalStorage.setItem(DEV_ORIGIN_INPUT, origin);
              window.location.href = target;
            }
          }}
        >
          이동
        </button>
      </p>
    </div>
  );
};
