import {CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import classNames from 'classnames';
import TMapSender from '@lcc/tmap-inapp';
import {HybridBridge} from 'utils/searchBar';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

import actions from 'ducks/actions';

import {LAYER_ID} from 'types/App';
import {EHistoryStack} from 'ducks/userInteraction/types';

import {EMPTY_ARRAY} from 'constant/Vars';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import useThrottle from 'hooks/useThrottle';
import useElement from 'hooks/useElement';
import useStartWhenTrue from 'hooks/useStartWhenTrue';

import ua from 'utils/uaParser';
import {isSameArray} from 'utils/array';

import {ReactComponent as IconCheck} from 'resource/images/@tmds_basic/ico_check_bold.svg';

import s from 'styles/components/CustomSelectLayer.module.scss';
import CheckboxIcon from './CheckboxIcon';

type TOptionId = string | number;
export type TOption = {id: TOptionId; label: string; icon?: string};

type TSelectedOption = TOption | TOption[];

type TProps = {
  isOpen: boolean;
  multiple?: boolean;
  options: TOption[];
  selectedItem?: TSelectedOption;
  selectAllOption?: TOption;
  maxSelectCount?: number;
  onChange?: (e: TSelectedOption) => void;
  onConfirm?: (e: TSelectedOption) => void;
  onClickItem?: (e: TSelectedOption) => void;
  onReset?: () => void;
};

const THROTTLE_TIME = 300;

export const CustomSelectLayer = ({
  isOpen,
  selectAllOption,
  multiple,
  options,
  onClickItem,
  onChange,
  onConfirm,
  onReset,
  maxSelectCount,
  selectedItem = EMPTY_ARRAY,
}: TProps) => {
  const {displayHeight, statusBarHeight, searchBarHeight} = useAppSelector(
    (state) => state.layout.appSize
  );
  const dispatch = useAppDispatch();
  // const focusTarget = useRef<HTMLInputElement>(null);

  const layerContainer = useElement({id: LAYER_ID});
  const refScroll = useRef<HTMLUListElement>(null);
  const refClickTarget = useRef<TOption>();
  const {isHybrid} = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
  }));

  const originOptionList = useMemo(() => [selectedItem].flat(), [selectedItem]);
  const [checkedOptions, setCheckedOptions] = useState<TOption[]>([]);
  const allOptions = useMemo(() => {
    return selectAllOption ? [selectAllOption, ...options] : options;
  }, [selectAllOption, options]);
  const ableToRun = useThrottle(THROTTLE_TIME);

  const handleClickItem = useCallback(
    (e, targetItem) => {
      e.preventDefault();

      const {id} = targetItem;
      let newList;

      // 전체 선택
      if (selectAllOption && id === selectAllOption.id) {
        newList = [selectAllOption];

        setCheckedOptions(newList);
      } else {
        if (multiple) {
          newList = checkedOptions.some((v) => v.id === id)
            ? checkedOptions.filter((item) => item.id !== id)
            : [...checkedOptions, targetItem];
        } else {
          newList = [targetItem];
        }

        if (maxSelectCount) {
          if (newList.length > maxSelectCount) {
            TMapSender.makeToast(`최대 ${maxSelectCount}개까지 선택할 수 있습니다.`);
          }
          newList = newList.slice(0, maxSelectCount);
        }

        // 아이템 선택 시 전체 선택 체크 제외
        newList = newList.filter((n) => n.id !== selectAllOption?.id);
        setCheckedOptions(newList);
      }

      onClickItem?.(targetItem);

      !isSameArray(originOptionList, newList) && onChange?.(newList);
    },
    [
      checkedOptions,
      selectAllOption,
      multiple,
      onChange,
      onClickItem,
      maxSelectCount,
      originOptionList,
    ]
  );

  const handleClickReset = useCallback(() => {
    if (!ableToRun()) {
      return;
    }

    setCheckedOptions([]);
    onReset?.();
  }, [ableToRun, onReset]);

  const handleClickConfirm = useCallback(() => {
    if (!ableToRun()) {
      return;
    }

    onConfirm?.(checkedOptions);
  }, [ableToRun, onConfirm, checkedOptions]);

  const handleTouchStart = useCallback((e, item) => {
    refClickTarget.current = item;
  }, []);

  const handleTouchEnd = useCallback(
    (e, item) => {
      if (refClickTarget.current && refClickTarget.current === item) {
        handleClickItem(e, item);
      }
      refClickTarget.current = undefined;
    },
    [handleClickItem]
  );

  const handleScroll = useCallback(() => {
    refClickTarget.current = undefined;
  }, []);

  useEffect(() => {
    setCheckedOptions(originOptionList);
  }, [originOptionList]);

  useEffect(() => {
    if (!refScroll.current) {
      return;
    }

    if (isOpen) {
      // https://github.com/willmcpo/body-scroll-lock#allowtouchmove
      disableBodyScroll(refScroll.current, {
        allowTouchMove: () => true,
      });
    } else {
      enableBodyScroll(refScroll.current);
    }
  }, [isOpen, layerContainer]);

  useEffect(() => {
    if (!isOpen) {
      setCheckedOptions(originOptionList);
    }
  }, [isOpen]);

  const handleChangeToTrue = useCallback(() => {
    dispatch(actions.userInteraction.pushHistoryStack(EHistoryStack.CUSTOM_SELECT_LAYER));
    isHybrid && HybridBridge.showModal();
  }, [dispatch, isHybrid]);

  const handleChangeToFalse = useCallback(() => {
    dispatch(actions.userInteraction.popHistoryStack());
    isHybrid && HybridBridge.hideModal();
  }, [dispatch, isHybrid]);

  useStartWhenTrue(isOpen, handleChangeToTrue, handleChangeToFalse);

  // useEffect(() => {
  //   focusTarget?.current?.focus();
  // }, [isOpen]);

  return (
    layerContainer &&
    createPortal(
      <div
        className={classNames(s.custom_select_wrapper, {
          [s.is_open]: isOpen,
        })}
        aria-modal={isOpen}
        style={
          {
            '--max-height': `${displayHeight - statusBarHeight - searchBarHeight - 10}px`, // 10px padding
          } as CSSProperties
        }
      >
        {/* <input ref={focusTarget} role="menu" aria-label="선택창" className={s.focus_label} /> */}
        <ul
          className={classNames(s.list, {
            [s.multiple]: multiple,
          })}
          ref={refScroll}
          onScroll={handleScroll}
          role="radiogroup"
        >
          {allOptions.map((item) => {
            const {label, icon, id} = item;
            const checked = checkedOptions.some((c) => c.id === id);

            return (
              <li
                className={classNames(s.item, {
                  [s.on]: checked,
                })}
                key={id}
                onTouchStart={ua.isTouchDevice ? (e) => handleTouchStart(e, item) : undefined}
                onTouchEnd={ua.isTouchDevice ? (e) => handleTouchEnd(e, item) : undefined}
                onMouseDown={!ua.isTouchDevice ? (e) => handleTouchStart(e, item) : undefined}
                onMouseUp={!ua.isTouchDevice ? (e) => handleTouchEnd(e, item) : undefined}
                role={multiple ? 'checkbox' : 'radio'}
                aria-checked={checked}
              >
                {icon && (
                  <span className={s.icon}>
                    <img src={icon} alt={`${label} 아이콘`} />
                  </span>
                )}

                <span className={s.label}>{label}</span>

                <span
                  className={classNames(s.check_icon, {
                    [s.is_multiple]: multiple,
                  })}
                >
                  {multiple && <CheckboxIcon on={checked} />}
                  {!multiple && <IconCheck />}
                </span>
              </li>
            );
          })}
        </ul>

        <div className={s.bottom_area}>
          {multiple && (
            <div className={s.buttons}>
              <button className={s.reset} onClick={handleClickReset}>
                초기화
              </button>
              <button className={s.confirm} onClick={handleClickConfirm}>
                필터 적용
              </button>
            </div>
          )}
        </div>
      </div>,
      layerContainer
    )
  );
};

export default CustomSelectLayer;
