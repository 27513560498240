import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TRemoteConfig} from './type';
import {remoteDefaultData} from './defaultData';

const initialState: TRemoteConfig = {
  lastUpdateTime: undefined,
  rawRemoteData: undefined,
  searchMainCategoryLink: [],
  searchResultCategoryLink: [],
  customCategoryPage: [],
  themeMarker: [],
  evFilterList: [],
  searchRecommendKeyword: [],
  tooltipList: [],
  saveMapLevelClustering: remoteDefaultData.saveMapLevelClustering,
};

const remoteSlice = createSlice({
  name: 'remote',
  initialState,
  reducers: {
    setRawConfig: (state, {payload}: PayloadAction<Record<string, any>>) => {
      state.rawRemoteData = {
        ...(state.rawRemoteData || {}),
        ...payload,
      };
    },
    setRemoteConfig: (state, {payload}: PayloadAction<TRemoteConfig>) => {
      state.lastUpdateTime = Date.now();
      state.searchMainCategoryLink = payload.searchMainCategoryLink;
      state.searchResultCategoryLink = payload.searchResultCategoryLink;
      state.customCategoryPage = payload.customCategoryPage;
      state.themeMarker = payload.themeMarker;
      state.evFilterList = payload.evFilterList;
      state.searchRecommendKeyword = payload.searchRecommendKeyword;
      state.tooltipList = payload.tooltipList;
      state.saveMapLevelClustering = payload.saveMapLevelClustering;
    },
  },
});

export default remoteSlice;
