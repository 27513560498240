import routes from '../routes';
import {useNavigate} from 'react-router-dom';

import styles from 'styles/components/TestLinkList.module.scss';

export const TestLinkList = ({rootPath, filterPaths}) => {
  const navigate = useNavigate();
  const routers = routes
    .map((router) => router.routes || [])
    .flat()
    .flat()
    .filter(
      (r) =>
        typeof r.path === 'string' &&
        r.path?.includes(rootPath) &&
        r.path !== rootPath &&
        !filterPaths.includes(r.path)
    );

  return (
    <div className={styles.link_wrap}>
      {routers.map((r) => {
        const [category, ...others] = (r.title || '')?.split(' > ');

        return (
          <div
            key={r.title}
            className={styles.link_box}
            onClick={() => typeof r.path === 'string' && navigate(r.path)}
          >
            {others.length < 1 ? (
              category
            ) : (
              <>
                <span>{others.join(' ')}</span>
                <span className={styles.link_category}>{category}</span>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
