import {useCallback, useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {ESearchAppMoveTarget, TQueryItem, TSearchContext} from '@lcc/tmap-inapp';

import actions from 'ducks/actions';

import {Paths, SearchHybridPages} from 'constant/RoutePath';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import {useAppLocation} from 'hooks/useAppLocation';
import {useSearchMainTab} from './useSearchMainTab';
import {ESearchTabs} from 'types/Search';
import HybridBridge from 'utils/searchBar';
import {useOnce} from './useOnce';
import useDimmedQuery from './useDimmedQuery';

const useHybridBridge = () => {
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();
  const {goBack} = useAppLocation();

  const {isOpen: isDimmedOpen} = useDimmedQuery();
  const {moveToSearch} = useMoveToTarget({from: EFromType.HYBRID_SEARCH_BAR});
  const {changeTab} = useSearchMainTab();
  const calloutInfo = useAppSelector((state) => state.userInteraction.calloutInfo);

  const isHybrid = useMemo(() => SearchHybridPages.includes(pathname), [pathname]);

  const onClickMapSelect = useCallback(() => {
    changeTab(ESearchTabs.MAP, {pushHistory: true});
  }, [changeTab]);

  const onSearch = useCallback(
    (data: TQueryItem) => {
      if (data?.query) {
        moveToSearch(data.query, true);
      }
    },
    [moveToSearch]
  );

  const onMove = useCallback(
    (target: ESearchAppMoveTarget) => {
      if (pathname === Paths.SearchHybridResult) {
        if (target === ESearchAppMoveTarget.SEARCH_MAIN_WITH_QUERY) {
          if (calloutInfo) {
            goBack();
            setTimeout(() => {
              goBack();
              HybridBridge.moveToMain();
            }, 300);
          } else {
            HybridBridge.moveToMain();
            goBack();
          }
        }
      }
    },
    [pathname, calloutInfo, goBack]
  );

  const onMoveBack = useCallback(() => {
    if (pathname === Paths.SearchHybridResult && !calloutInfo && !isDimmedOpen) {
      HybridBridge.moveToMain();
    }

    if (isDimmedOpen) {
      return;
    }

    goBack();
  }, [pathname, goBack, calloutInfo, isDimmedOpen]);

  const onSearchContext = useCallback(
    (context: TSearchContext) => {
      if (context?.recentQueries) {
        dispatch(actions.userInfo.setRecentQueries(context.recentQueries));
      }

      if (context?.numSearchBarHeight) {
        dispatch(actions.layout.setSearchBarHeight(context.numSearchBarHeight));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(actions.layout.setHybrid(isHybrid));
  }, [dispatch, isHybrid]);

  useOnce(isHybrid, () => {
    HybridBridge.init({
      onSearch,
      onMove,
      onMoveBack,
      onSearchContext,
      onClickMapSelect,
    });
    HybridBridge.getSearchContext();
  });

  useEffect(() => {
    if (isHybrid) {
      HybridBridge.updateCallback({
        onSearch,
        onMove,
        onMoveBack,
        onSearchContext,
        onClickMapSelect,
      });
    }
  }, [isHybrid, onSearch, onMove, onMoveBack, onSearchContext, onClickMapSelect]);
};

export default useHybridBridge;
