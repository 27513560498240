import {MutableRefObject, useCallback, useMemo} from 'react';
import {sendSearchClickLog} from 'utils/logManager';
import {ERecentItemType} from 'types/Search';
import {TActionId} from 'types/Log';
import {useAppSelector} from 'ducks/hooks';
import useRecentList from 'hooks/useRecentList';
import useRecentQuery from 'hooks/useRecentQuery';
import {useOnce} from 'hooks/useOnce';
import usePrevious from 'hooks/usePrevious';
import SearchRecentItem from 'components/search/SearchRecentItem';
import SearchScrollTop from './SearchScrollTop';
import useFavorite from 'hooks/useFavorite';
import {TPersonalPlaceItem} from 'ducks/userInfo/types';

import s from 'styles/components/search/SearchRecentList.module.scss';

const TAP_ACTION_ID_MAP: Record<ERecentItemType, TActionId> = {
  [ERecentItemType.POI]: 'tap.lastD',
  [ERecentItemType.PUBLIC_POI]: 'tap.lastT',
  [ERecentItemType.HOME]: 'tap.lastD_bookmark',
  [ERecentItemType.OFFICE]: 'tap.lastD_bookmark',
  [ERecentItemType.FAVORITE]: 'tap.lastD_bookmark',
  [ERecentItemType.FAVORITE_STATION]: 'tap.lastT_bookmark',
  [ERecentItemType.FAVORITE_SUBWAY]: 'tap.lastT_bookmark',
  [ERecentItemType.BUS_STATION]: 'tap.lastT',
  [ERecentItemType.SUBWAY]: 'tap.lastT',
  [ERecentItemType.QUERY]: 'tap.lastS',
};

const TYPE_MAP: Record<ERecentItemType, string> = {
  [ERecentItemType.POI]: 'POI',
  [ERecentItemType.PUBLIC_POI]: 'PLACE',
  [ERecentItemType.HOME]: 'home',
  [ERecentItemType.OFFICE]: 'office',
  [ERecentItemType.FAVORITE]: 'bookmark',
  [ERecentItemType.FAVORITE_STATION]: 'BUSSTATION',
  [ERecentItemType.FAVORITE_SUBWAY]: 'SUBWAY',
  [ERecentItemType.BUS_STATION]: 'BUSSTATION',
  [ERecentItemType.SUBWAY]: 'SUBWAY',
  [ERecentItemType.QUERY]: 'QUERY',
};

export const RECOMMENDATION_POSITION = 6;

type TProps = {
  wrapper: MutableRefObject<HTMLDivElement | null>;
};

export const SearchRecentList = ({wrapper}: TProps) => {
  const {recentList: lastRecentList, recentListApiLoaded} = useRecentList();
  const {isHybrid} = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
  }));
  const {updateLocalItems, getLocalItems} = useRecentQuery();
  const prevRecentList = usePrevious(lastRecentList);
  const poiList = useMemo(
    () =>
      lastRecentList
        .filter((v) => !!v.poiInfo)
        .map((v) => {
          const poiInfo = v.poiInfo as TPersonalPlaceItem;
          return {
            pkey: poiInfo?.pkey || '',
            poiId: poiInfo?.poiId || '',
            navX: poiInfo?.navX || '',
            navY: poiInfo?.navY || '',
          };
        }),
    [lastRecentList]
  );
  const {getFavoriteGroupColor} = useFavorite({list: poiList});

  const recentList = useMemo(
    () => (recentListApiLoaded ? lastRecentList : prevRecentList),
    [lastRecentList, recentListApiLoaded, prevRecentList]
  );

  const handleClickItem = useCallback((data, idx) => {
    const actionId = TAP_ACTION_ID_MAP[data.type];
    const type = TYPE_MAP[data.type];

    sendSearchClickLog(actionId, {
      pkey: data.poiInfo?.pkey || data.poiInfo?.poiId,
      search_query: data.name,
      list_seq: idx,
      type,
    });
  }, []);

  useOnce(!isHybrid, () => {
    updateLocalItems(getLocalItems() as any);
  });

  if (!recentListApiLoaded) {
    return null;
  }

  return (
    <div className={s.wrap}>
      {(recentList || []).length > 0 ? (
        <ul className={s.list}>
          {(recentList || []).map((data, idx) => {
            const poiInfo = data.poiInfo as TPersonalPlaceItem;
            const favoriteGroupColor = getFavoriteGroupColor({
              pkey: poiInfo?.pkey || '',
              poiId: poiInfo?.poiId || '',
              navX: poiInfo?.navX || '',
              navY: poiInfo?.navY || '',
            });
            const dataType =
              data.type === ERecentItemType.POI && !!favoriteGroupColor
                ? ERecentItemType.FAVORITE
                : data.type;
            const needColor = dataType === ERecentItemType.FAVORITE;

            return (
              <SearchRecentItem
                key={data.id}
                {...data}
                type={dataType}
                favoriteGroupColor={needColor ? favoriteGroupColor : null}
                onClick={() => handleClickItem(data, idx)}
              />
            );
          })}
        </ul>
      ) : (
        <div className={s.no_result}>최근 검색한 기록이 없습니다.</div>
      )}
      <SearchScrollTop data={recentList} wrapper={wrapper} />
    </div>
  );
};
