import {ECategoryGroup} from 'types/App';
import {MARKER_LABEL_MAX} from 'constant/Map';
import {EMapStyle} from '@lcc/tmap-inapp';

import MarkerPath from 'resource/pubImages/markers/@markerPath';

import colors from 'styles/modules/ExportedVariables.module.scss';
import AccommodationPriceMarker from 'components/AccommodationPriceMarker';
import {CustomMarker} from 'components/CustomMarker';
import {EVMarker} from 'components/EVMarker';

import {EMarkerType} from 'types/Map';
import {TMarkerStyleConfig} from 'ducks/map/types';
import {getMarkerStyles} from 'utils/tmapUtils';
import {ECategoryCode} from 'constant/Place';

type TMarkerOptions = {
  mapStyle?: EMapStyle;
  markerConfig?: TMarkerStyleConfig;
};

type TFilterCustomMarker = {
  marker?: JSX.Element;
  label?: string;
  markerType?: string;
};

const RESTAURANT_IMG_MAP = {
  [ECategoryCode.RESTAURANT]: (isDay) => ({
    icon: isDay
      ? MarkerPath.mapmarker.store.icon.restaurant.day
      : MarkerPath.mapmarker.store.icon.restaurant.night,
    marker: isDay
      ? MarkerPath.mapmarker.store.marker.restaurant.day
      : MarkerPath.mapmarker.store.marker.restaurant.night,
  }),
  [ECategoryCode.CHICKEN]: (isDay) => ({
    icon: isDay
      ? MarkerPath.mapmarker.store.icon.chicken.day
      : MarkerPath.mapmarker.store.icon.chicken.night,
    marker: isDay
      ? MarkerPath.mapmarker.store.marker.chicken.day
      : MarkerPath.mapmarker.store.marker.chicken.night,
  }),
  [ECategoryCode.BAKERY]: (isDay) => ({
    icon: isDay
      ? MarkerPath.mapmarker.store.icon.dessert.day
      : MarkerPath.mapmarker.store.icon.dessert.night,
    marker: isDay
      ? MarkerPath.mapmarker.store.marker.dessert.day
      : MarkerPath.mapmarker.store.marker.dessert.night,
  }),
};

const OIL_BRANDS_IMG_MAP = {
  [ECategoryCode.S_OIL]: {
    icon: MarkerPath.mapmarker.oil.icon.soil,
    marker: MarkerPath.mapmarker.oil.marker.soil,
  },
  [ECategoryCode.SK]: {
    icon: MarkerPath.mapmarker.oil.icon.sk,
    marker: MarkerPath.mapmarker.oil.marker.sk,
  },
  [ECategoryCode.GS]: {
    icon: MarkerPath.mapmarker.oil.icon.gs,
    marker: MarkerPath.mapmarker.oil.marker.gs,
  },
  [ECategoryCode.OIL_BANK]: {
    icon: MarkerPath.mapmarker.oil.icon.oilbank,
    marker: MarkerPath.mapmarker.oil.marker.oilbank,
  },
  [ECategoryCode.E1]: {
    icon: MarkerPath.mapmarker.oil.icon.e1,
    marker: MarkerPath.mapmarker.oil.marker.e1,
  },
};

const CONVENIENCE_STORE_IMG_MAP = {
  [ECategoryCode.CU]: {
    icon: MarkerPath.mapmarker.store.icon.cu,
    marker: MarkerPath.mapmarker.store.marker.cu,
  },
  [ECategoryCode.GS25]: {
    icon: MarkerPath.mapmarker.store.icon.gs25,
    marker: MarkerPath.mapmarker.store.marker.gs25,
  },
  [ECategoryCode.SEVEN_ELEVEN]: {
    icon: MarkerPath.mapmarker.store.icon.sevenEleven,
    marker: MarkerPath.mapmarker.store.marker.sevenEleven,
  },
  [ECategoryCode.MINI_STOP]: {
    icon: MarkerPath.mapmarker.store.icon.ministop,
    marker: MarkerPath.mapmarker.store.marker.ministop,
  },
  [ECategoryCode.EMART]: {
    icon: MarkerPath.mapmarker.store.icon.emart24,
    marker: MarkerPath.mapmarker.store.marker.emart24,
  },
};

export const filterCustomMarker = (
  item,
  active = false,
  markerOption: TMarkerOptions = {}
): TFilterCustomMarker => {
  const {mapStyle, markerConfig} = {mapStyle: EMapStyle.DAY, markerConfig: {}, ...markerOption};
  const isDay = mapStyle === EMapStyle.DAY;

  const customMarkerCategory =
    markerConfig && (item.markerCategories || []).find((v) => markerConfig[v]);

  if (customMarkerCategory) {
    const {marker, icon, description} = markerConfig[customMarkerCategory];

    return {
      marker:
        marker && icon ? (
          <CustomMarker
            active={active}
            name={item.poiName || item.listName}
            activeImageMarker={{src: marker[isDay ? 0 : 1]}}
            iconImageMarker={{src: icon[isDay ? 0 : 1]}}
            labelStyle={
              isDay
                ? {fontSize: '14px', color: '#171819', borderColor: '#FFFFFF'}
                : {fontSize: '14px', color: '#FFFFFF', borderColor: '#000000'}
            }
          />
        ) : undefined,
      label: item.poiName || item.listName,
      markerType: description,
    };
  }

  if (item?.categoryGroup === ECategoryGroup.EV_CHARGING_STATION && item.special.evChargerInfo) {
    return {
      marker: (
        <EVMarker
          active={active}
          {...item.special.evChargerInfo}
          name={item.poiName || item.listName}
          isTesla={!!item.special.isTesla}
        />
      ),
      label: item.poiName || item.listName,
      markerType: ECategoryGroup.EV_CHARGING_STATION,
    };
  }

  if (
    item?.mainCategory &&
    (item?.mainCategory.includes(ECategoryCode.GAS_STATION) ||
      item.mainCategory.includes(ECategoryCode.GAS_CHARGING_STATION))
  ) {
    const categoryCode = item.mainCategory;

    return {
      marker: (
        <CustomMarker
          active={active}
          name={item.poiName || item.listName}
          activeImageMarker={{
            src: OIL_BRANDS_IMG_MAP[categoryCode]?.marker || MarkerPath.mapmarker.oil.marker.oil,
          }}
          iconImageMarker={{
            src: OIL_BRANDS_IMG_MAP[categoryCode]?.icon || MarkerPath.mapmarker.oil.icon.oil,
          }}
          labelStyle={
            isDay
              ? {fontSize: '14px', color: colors.gray900, borderColor: colors.white}
              : {fontSize: '14px', color: colors.white, borderColor: colors.gray900}
          }
        />
      ),
    };
  }

  if (item.categoryGroup === ECategoryGroup.PARKING) {
    // 일반 주차장 : categoryGroup: “PARKING”
    // 제휴주차장 : categoryGroup: “PARKING” && “isTmapPark”:true
    const parkingMarker = MarkerPath.mapmarker.parking;
    const imgSrc = item.tag.isTmapPark
      ? {
          icon: isDay ? parkingMarker.icon.day.partner : parkingMarker.icon.night.partner,
          marker: isDay ? parkingMarker.marker.day.partner : parkingMarker.marker.night.partner,
        }
      : {
          icon: isDay ? parkingMarker.icon.day.general : parkingMarker.icon.night.general,
          marker: isDay ? parkingMarker.marker.day.general : parkingMarker.marker.night.general,
        };

    const labelStyle = isDay
      ? {color: colors.gray900, borderColor: colors.white}
      : {color: colors.white, borderColor: colors.gray900};

    return {
      marker: (
        <CustomMarker
          active={active}
          name={item.poiName || item.listName}
          labelStyle={{fontSize: '14px', ...labelStyle}}
          activeImageMarker={{src: imgSrc.marker, width: 39, height: 53}}
          iconImageMarker={{src: imgSrc.icon, width: 26, height: 28}}
        />
      ),
      label: item.poiName || item.listName,
      markerType: ECategoryGroup.PARKING,
    };
  }

  if (item.categoryGroup === ECategoryGroup.ACCOMMODATION) {
    const accommodationInfo = item.special?.accommodationInfo;
    const {
      imageSrc: src,
      width,
      height,
    } = getMarkerStyles(active ? EMarkerType.ACTIVE : EMarkerType.INACTIVE);
    const name = item.poiName || item.listName;
    const label =
      (name.length || 0) > MARKER_LABEL_MAX ? `${name.slice(0, MARKER_LABEL_MAX)}...` : name;
    const labelStyle = isDay
      ? {color: colors.gray900, borderColor: colors.white}
      : {color: colors.white, borderColor: colors.gray900};

    return {
      marker: (
        <CustomMarker
          active={active}
          name={label}
          activeImageMarker={{src, width, height}}
          iconImageMarker={{src, width, height}}
          useDualMode={true}
          subName={<AccommodationPriceMarker data={accommodationInfo} />}
          labelStyle={{fontSize: '14px', ...labelStyle}}
        />
      ),
      label: name,
      markerType: ECategoryGroup.ACCOMMODATION,
    };
  }

  if (item?.mainCategory && item.mainCategory.includes(ECategoryCode.CHICKEN)) {
    const imageMarkerSrc = RESTAURANT_IMG_MAP[ECategoryCode.CHICKEN](isDay);

    return {
      marker: (
        <CustomMarker
          active={active}
          name={item.poiName || item.listName}
          activeImageMarker={{
            src: imageMarkerSrc.marker,
          }}
          iconImageMarker={{
            src: imageMarkerSrc.icon,
          }}
          labelStyle={
            isDay
              ? {fontSize: '14px', color: colors.gray900, borderColor: colors.white}
              : {fontSize: '14px', color: colors.white, borderColor: colors.gray900}
          }
        />
      ),
      label: item.poiName || item.listName,
      markerType: ECategoryGroup.DEFAULT,
    };
  }

  if (item?.mainCategory && item.mainCategory.includes(ECategoryCode.BAKERY)) {
    const imageMarkerSrc = RESTAURANT_IMG_MAP[ECategoryCode.BAKERY](isDay);

    return {
      marker: (
        <CustomMarker
          active={active}
          name={item.poiName || item.listName}
          activeImageMarker={{
            src: imageMarkerSrc.marker,
          }}
          iconImageMarker={{
            src: imageMarkerSrc.icon,
          }}
          labelStyle={
            isDay
              ? {fontSize: '14px', color: colors.gray900, borderColor: colors.white}
              : {fontSize: '14px', color: colors.white, borderColor: colors.gray900}
          }
        />
      ),
      label: item.poiName || item.listName,
      markerType: ECategoryGroup.DEFAULT,
    };
  }

  // 24.08.28 BAR도 추가 https://tmobi.atlassian.net/browse/LOCALQA-563
  // mainCategory 구조상 CHICKEN과 BAKERY 우선 판단 (RESTAURANT가 상위 카테고리)
  if (
    item?.mainCategory &&
    (item.mainCategory.includes(ECategoryCode.RESTAURANT) ||
      item.mainCategory.includes(ECategoryCode.BAR))
  ) {
    const imageMarkerSrc = RESTAURANT_IMG_MAP[ECategoryCode.RESTAURANT](isDay);

    return {
      marker: (
        <CustomMarker
          active={active}
          name={item.poiName || item.listName}
          activeImageMarker={{
            src: imageMarkerSrc.marker,
          }}
          iconImageMarker={{
            src: imageMarkerSrc.icon,
          }}
          labelStyle={
            isDay
              ? {fontSize: '14px', color: colors.gray900, borderColor: colors.white}
              : {fontSize: '14px', color: colors.white, borderColor: colors.gray900}
          }
        />
      ),
      label: item.poiName || item.listName,
      markerType: ECategoryGroup.DEFAULT,
    };
  }

  if (item?.mainCategory && item.mainCategory.includes(ECategoryCode.CAFE)) {
    return {
      marker: (
        <CustomMarker
          active={active}
          name={item.poiName || item.listName}
          activeImageMarker={{
            src: isDay
              ? MarkerPath.mapmarker.store.marker.cafe.day
              : MarkerPath.mapmarker.store.marker.cafe.night,
          }}
          iconImageMarker={{
            src: isDay
              ? MarkerPath.mapmarker.store.icon.cafe.day
              : MarkerPath.mapmarker.store.icon.cafe.night,
          }}
          labelStyle={
            isDay
              ? {fontSize: '14px', color: colors.gray900, borderColor: colors.white}
              : {fontSize: '14px', color: colors.white, borderColor: colors.gray900}
          }
        />
      ),
      label: item.poiName || item.listName,
      markerType: ECategoryGroup.DEFAULT,
    };
  }

  if (item?.mainCategory && item.mainCategory.includes(ECategoryCode.CONVENIENCE_STORE)) {
    const categoryCode = item.mainCategory;

    return {
      marker: (
        <CustomMarker
          active={active}
          name={item.poiName || item.listName}
          activeImageMarker={{
            src:
              CONVENIENCE_STORE_IMG_MAP[categoryCode]?.marker ||
              MarkerPath.mapmarker.store.marker.store,
          }}
          iconImageMarker={{
            src:
              CONVENIENCE_STORE_IMG_MAP[categoryCode]?.icon ||
              MarkerPath.mapmarker.store.icon.store,
          }}
        />
      ),
      label: item.poiName || item.listName,
      markerType: ECategoryGroup.DEFAULT,
    };
  }

  return {
    marker: undefined,
    label: undefined,
    markerType: ECategoryGroup.DEFAULT,
  };
};
