import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useParseQueryLocation} from './useParseQueryLocation';
import {ESearchTabs} from 'types/Search';
import {useNavigate} from 'react-router-dom';
import {generateUrl} from 'utils/url';
import {EAppRequestMode} from 'types/App';

export const useSearchMainTab = () => {
  const {pathname, queries} = useParseQueryLocation();
  const navigate = useNavigate();

  const currentTab = useMemo(() => queries.currentTab, [queries]);
  const prevTab = useRef('');

  const changeTab = useCallback(
    (tab, {pushHistory}) => {
      const newQuery = {
        ...queries,
        currentTab: tab,
      };
      const newUrl = generateUrl(pathname, newQuery);

      if (pushHistory) {
        navigate(newUrl);
      } else {
        navigate(newUrl, {replace: true});
      }
    },
    [navigate, pathname, queries]
  );

  const isSearchMain = useMemo(
    () => currentTab === ESearchTabs.RECENT || currentTab === ESearchTabs.RECOMMEND,
    [currentTab]
  );
  const isEventTab = useMemo(
    () => queries.reqMode === EAppRequestMode.EVENT_PAGE,
    [queries.reqMode]
  );
  const isFromTPlaceHome = useMemo(
    () => queries.reqMode === EAppRequestMode.TPLACE_HOME,
    [queries.reqMode]
  );

  const moveBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    prevTab.current = currentTab;
  }, [currentTab]);

  return {
    currentTab,
    prevTab: prevTab.current,
    isSearchMain,
    isEventTab,
    isFromTPlaceHome,
    changeTab,
    moveBack,
  };
};
