import {useCallback, useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {ESearchAppMoveTarget, TQueryItem, TSearchContext} from '@lcc/tmap-inapp';
import HybridBridge from 'utils/searchBar';

import actions from 'ducks/actions';
import {EHistoryStack} from 'ducks/userInteraction/types';

import {isProdEnv} from 'constant/Env';
import {Paths, SearchHybridPages} from 'constant/RoutePath';
import {ETempWindowKey} from 'constant/Storage';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import {useAppLocation} from 'hooks/useAppLocation';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';

// import {localDevLog} from 'utils/dev';

const useLegacyHybridBridge = () => {
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();
  const {goBack} = useAppLocation();
  const {queries} = useParseQueryLocation();

  const {historyStack} = useAppSelector((state) => ({
    historyStack: state.userInteraction.historyStack,
  }));
  const {moveToSearch} = useMoveToTarget({from: EFromType.HYBRID_SEARCH_BAR});

  const isHybrid = useMemo(() => SearchHybridPages.includes(pathname), [pathname]);

  const onSearch = useCallback(
    (data: TQueryItem) => {
      if (!isHybrid) {
        return;
      }

      // localDevLog('[Hybrid Bridge onSearch]', data);

      if (data?.query) {
        moveToSearch(data.query, true);
      }
    },
    [isHybrid, moveToSearch]
  );

  const onMove = useCallback(
    (target: ESearchAppMoveTarget) => {
      if (!isHybrid) {
        return;
      }

      // localDevLog('[Hybrid Bridge onMove]', target);

      if (pathname === Paths.SearchHybridResult) {
        if (target === ESearchAppMoveTarget.SEARCH_MAIN) {
          window[ETempWindowKey.HISTORY_BACK_FROM_APP] = true;
          goBack();
        } else if (target === ESearchAppMoveTarget.SEARCH_MAIN_WITH_QUERY) {
          if ((historyStack || []).length > 0) {
            goBack();

            setTimeout(() => {
              window[ETempWindowKey.NOW_QUERY] = queries.searchQuery;
              window[ETempWindowKey.HISTORY_BACK_FROM_APP] = true;
              // sendClickLog('tap.searchbox');
              goBack();
            }, 300);

            return;
          }

          window[ETempWindowKey.NOW_QUERY] = queries.searchQuery;
          window[ETempWindowKey.HISTORY_BACK_FROM_APP] = true;
          // sendClickLog('tap.searchbox');
          goBack();
        }
      }
    },
    [isHybrid, pathname, goBack, queries, historyStack]
  );

  const onMoveBack = useCallback(() => {
    if (!isHybrid) {
      return;
    }

    if (historyStack?.slice(-1)[0] === EHistoryStack.CALLOUT) {
      dispatch(actions.userInteraction.clearCalloutInfo());
      return;
    }

    if (historyStack?.length === 0) {
      if (pathname === Paths.SearchHybridResult) {
        HybridBridge.moveToMain();
      }
    }

    goBack();
  }, [dispatch, pathname, historyStack, goBack, isHybrid]);

  const onSearchContext = useCallback(
    (context: TSearchContext) => {
      if (!isHybrid) {
        return;
      }

      // localDevLog('[Hybrid Bridge onSearchContext]', context);

      if (context?.recentQueries) {
        dispatch(actions.userInfo.setRecentQueries(context.recentQueries));
      }

      if (context?.numSearchBarHeight) {
        dispatch(actions.layout.setSearchBarHeight(context.numSearchBarHeight));
      }
    },
    [isHybrid, dispatch]
  );

  useEffect(() => {
    dispatch(actions.layout.setHybrid(isHybrid));
  }, [dispatch, isHybrid]);

  useEffect(() => {
    if (!isHybrid) {
      return;
    }

    // localDevLog('[Hybrid Bridge init]');

    HybridBridge.init({
      onSearch,
      onMove,
      onMoveBack,
      onSearchContext,
    });

    if (!isProdEnv) {
      window['HybridBridge'] = HybridBridge;
    }

    HybridBridge.getSearchContext();

    // hybrid에서는 검색창이 없기에 별도로 호출
    // -> 앱에서 늦게 뜨는 이슈가 있어 직접 올리기로 함.
    // -> 올라갔다 사용자가 빠르게 내렸는데 다시 올라가는것 방지하기 위해 제거
    // TMapSender.showSoftKeyboard(true);
  }, [isHybrid]);

  useEffect(() => {
    if (isHybrid) {
      HybridBridge.updateCallback({
        onSearch,
        onMove,
        onMoveBack,
        onSearchContext,
      });
    } else {
      HybridBridge.updateCallback({
        onSearch: () => undefined,
        onMove: () => undefined,
        onMoveBack: () => undefined,
        onSearchContext: () => undefined,
      });
    }
  }, [isHybrid, onSearch, onMove, onMoveBack, onSearchContext]);
};

export default useLegacyHybridBridge;
