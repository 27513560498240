import {useState} from 'react';
import classNames from 'classnames';
import {IcoInfoFilled} from 'components/@tmds/icons/IcoInfoFilled';
import AiGuideTooltip from 'components/AiGuideTooltip';
import {ReactComponent as IconTmapAI} from 'resource/images/icon_tmap_ai.svg';

import s from 'styles/components/search/SearchTmapAI.module.scss';

type TProps = {
  tooltipContents?: React.ReactNode;
  link?: {
    url: string;
    text: React.ReactNode;
  };
  tooltipClassName?: string;
};

const SearchTmapAI = ({tooltipContents, link, tooltipClassName}: TProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <div className={s.wrap}>
      <div className={s.icon_wrap} onClick={() => setShowTooltip((prev) => !prev)}>
        <IconTmapAI />
        <div className={s.icon}>
          <IcoInfoFilled width={18} height={18} color="gray300" />
        </div>
      </div>

      <div className={classNames(s.tooltip_wrap, tooltipClassName)}>
        {showTooltip ? (
          <AiGuideTooltip
            className={s.update_tooltip}
            onClickExit={() => setShowTooltip(false)}
            contents={tooltipContents}
            link={link}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SearchTmapAI;
