import {SearchMainPages, SearchResultPages} from 'constant/RoutePath';
import {useMatchPaths} from './useMatchPaths';

const usePageInfo = () => {
  const isSearchMainPage = useMatchPaths(SearchMainPages);
  const isSearchResultPage = useMatchPaths(SearchResultPages);

  return {
    isSearchMainPage,
    isSearchResultPage,
    isSearchPage: isSearchMainPage || isSearchResultPage,
  };
};

export default usePageInfo;
