import {SearchMainPages, SearchResultPages} from 'constant/RoutePath';
import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import LocalStorage from '@lcc/web-storage';
import {NEW_HOME_KEY} from 'utils/dev';
import {isNewApp} from 'utils/tmapUtils';

// [TBD] ua parser 적용 작업 이후
// startsWith -> ua.app.semver 숫자 확인으로 수정
const isNewVersion = isNewApp();

export const useSearchPageVisible = () => {
  const {pathname} = useLocation();
  const isLegacyPage = useMemo(() => {
    if (isNewVersion) {
      return false;
    }

    return !LocalStorage.getItem(NEW_HOME_KEY);
  }, []);

  return {
    showMain: SearchMainPages.includes(pathname),
    showResult: SearchResultPages.includes(pathname),
    isLegacyPage,
  };
};
