import {getDefaultApiStatus} from '../../utils/apis';
import fetcher from '../../utils/fetcher';
import {API_PATH} from '../../constant/Api';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {ETRankSort, TRANK_MAX_COUNT, TRankItem, TRankProps} from '../rank/types';
import {StoreState} from '../store';

const ACTION_PREFIX = 'search';

const initialState = {
  rankData: {
    result: getDefaultApiStatus({
      list: [] as TRankItem[],
      latestUpdateTime: '',
      totalCount: 0,
    }),
  },
  reverseGeoInfo: {
    regionName1: '',
    regionName2: '',
    regionName3: '',
  },
};

export const fetchRank = createAsyncThunk(
  `${ACTION_PREFIX}/trank`,
  async (props: Partial<TRankProps>, creator) => {
    const storeState = creator.getState() as StoreState;
    const {
      lat,
      lon,
      page = 1,
      size = TRANK_MAX_COUNT,
      sort,
      areaCode1,
      areaCode2,
      areaCode3,
      featureOr,
      featureAnd,
    } = props;
    const {lat: userRealLat, lon: userRealLon} = storeState.map.userPosition || {};
    const {lat: nowCenterLat, lon: nowCenterLon} = storeState.map.nowCenter || {};

    const params: Partial<TRankProps> = {
      ...props,
      lat: lat || nowCenterLat || 0,
      lon: lon || nowCenterLon || 0,
      userRealLat: props.userRealLat || userRealLat || 0,
      userRealLon: props.userRealLon || userRealLon || 0,
      playEatYn: 'Y',
      page: page,
      size: size,
      sort: ETRankSort.TMAP_RANK || sort,
      areaCode1: areaCode1,
      areaCode2: areaCode2,
      areaCode3: areaCode3,
      featureOr: featureOr || '',
      featureAnd: featureAnd || '',
    };
    const geoFetcher = fetcher.get(API_PATH.GET_REVERSE_GEOCODE, {
      params: {lon: props.lon, lat: props.lat},
    });

    await geoFetcher.then(({data}) => {
      const {
        areaDepth1Code,
        areaDepth2Code,
        areaDepth3Code,
        regionName1,
        regionName2,
        regionName3,
      } = data.data;
      params.areaCode1 = areaDepth1Code;
      params.areaCode2 = areaDepth2Code;
      params.areaCode3 = areaDepth3Code;

      creator.dispatch(
        searchRecommendSlice.actions.setReverseGeoInfo({
          regionName1: regionName1 || '',
          regionName2: regionName2 || '',
          regionName3: regionName3 || '',
        })
      );
    });
    return await fetcher.post(API_PATH.POST_TRANK_LIST, params).then((response) => response.data);
  }
);

const getRankStatePending = (state) => {
  state.rankData.result.loading = true;
  state.rankData.result.error = false;
  state.rankData.result.loaded = false;
};
const getRankStateRejected = (state) => {
  state.rankData.result.loading = false;
  state.rankData.result.error = true;
  state.rankData.result.loaded = true;
};
const getRankStateFulfilled = (state, action) => {
  const data = action.payload.data;
  state.rankData.result.loading = false;
  state.rankData.result.error = false;
  state.rankData.result.loaded = true;
  state.rankData.result.data.list = data?.docs || [];
  state.rankData.result.data.latestUpdateTime = data?.latestUpdateTime;
  state.rankData.result.data.totalCount = data?.totalCount;
};

const searchRecommendSlice = createSlice({
  name: 'recommend',
  initialState,
  reducers: {
    setReverseGeoInfo(state, action) {
      state.reverseGeoInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRank.pending, getRankStatePending)
      .addCase(fetchRank.rejected, getRankStateRejected)
      .addCase(fetchRank.fulfilled, getRankStateFulfilled);
  },
});

export default searchRecommendSlice;
