import classNames from 'classnames';
import {IcoExit} from 'components/@tmds/icons/IcoExit';
import s from 'styles/components/AiGuideTooltip.module.scss';

type TLinkType = Nullable<{
  url: string;
  text: React.ReactNode;
}>;

type TProps = {
  className?: string;
  onClickExit: () => void;
  contents: React.ReactNode;
  link?: TLinkType;
};

const AiGuideTooltip = ({className, onClickExit, contents, link}: TProps) => {
  return (
    <div className={classNames(s.wrap, className)}>
      <div className={s.contents}>
        {contents}

        {!!link && (
          <a className={s.link} href={link.url} draggable={false}>
            {link.text}
          </a>
        )}
      </div>
      <div className={s.exit} onClick={onClickExit}>
        <IcoExit width={20} height={20} color="gray500" />
      </div>
    </div>
  );
};

export default AiGuideTooltip;
