import {CSSProperties, MouseEventHandler, useMemo} from 'react';
import classNames from 'classnames';
import {useParsePoiData} from 'hooks/useParsePoiData';
import useLogger from 'hooks/useLogger';
import {useAppSelector} from 'ducks/hooks';
import {ECategoryGroup, EDetailButtonType, TPoiImageInfo, TSpecialData} from 'types/App';
import {
  getDecoBusinessHour,
  getDecoRealTimeParkingLot,
  getDecorateEV,
  getDecorateGasPrice,
  getDecoParkingLotFeeInfo,
  getDecoAccommodationInfo,
} from 'utils/special';
import {setComma} from 'utils/formatter';
import {getImageUrl} from 'utils/url';
import {EImageSize} from 'types/Image';
import Thumbnail from './Thumbnail';
import {ItemTag} from './ItemTag';
import {PoiListItemSpecialContent} from './PoiListItemSpecialContent';
import PoiListItemCouponLink from './PoiListItemCouponLink';
import {IcoRouteTurnFilled} from 'components/@tmds/icons/IcoRouteTurnFilled';
import {IcoLineBetween} from 'components/@tmds/icons/IcoLineBetween';

import s from 'styles/components/PoiListItem.module.scss';

const MAX_CATEGORY_NAME = 10;

type TProps = {
  actionButtonType: EDetailButtonType;
  idx: number;
  focus?: boolean;
  poiData: {
    listName?: string;
    categoryName?: string;
    categoryGroup?: ECategoryGroup;
    special?: TSpecialData;
    imageInfo?: TPoiImageInfo[];
    pkey: string;
  };
  onClickRouteButton?: MouseEventHandler;
  onClickItem?: (e) => void;
};

export const PoiListItem = ({
  idx,
  poiData,
  focus,
  actionButtonType,
  onClickRouteButton,
  onClickItem,
}: TProps) => {
  const {oilGasFilter} = useAppSelector((state) => ({
    oilGasFilter: state.place.placeQuery,
  }));

  const {
    distance,
    address,
    imageInfo: images,
    headingScore,
    tags,
    coupon,
  } = useParsePoiData(poiData);
  const {sendCouponClickLog} = useLogger();

  const {rightSpecial, bottomSpecial} = useMemo(() => {
    const {special, categoryGroup} = poiData;

    let bottom, right;

    if (categoryGroup === ECategoryGroup.GAS_CHARGING_STATION) {
      right = getDecorateGasPrice(special?.gasStationInfo, oilGasFilter);
    }

    if (categoryGroup === ECategoryGroup.EV_CHARGING_STATION) {
      right = getDecorateEV(special?.evChargerInfo);
    }

    if (special?.businessHourStatus || special?.offDay || special?.businessHours) {
      bottom = getDecoBusinessHour(special);
    }

    if (special?.realTimeParkingLotInfo) {
      right = getDecoRealTimeParkingLot(special?.realTimeParkingLotInfo);
    }

    if (
      (special?.parkingFeeInfo || (special?.tmapParkingLot?.tickets || []).length > 0) &&
      !(special?.businessHourStatus || special?.offDay || special?.businessHours)
    ) {
      //https://tmobi.atlassian.net/browse/LOCALQA-689
      bottom = getDecoParkingLotFeeInfo(special?.parkingFeeInfo, special?.tmapParkingLot);
    }

    if (categoryGroup === ECategoryGroup.ACCOMMODATION) {
      bottom = getDecoAccommodationInfo(special?.accommodationInfo);
    }

    return {rightSpecial: right, bottomSpecial: bottom};
  }, [oilGasFilter, poiData]);

  return (
    <div
      className={s.wrap}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClickItem?.(e);
      }}
    >
      <div className={classNames(s.title_wrap, s.item)}>
        <span className={s.title} data-style-node="title">
          {poiData.listName}
        </span>
        <span className={s.category} data-style-node="category">
          {poiData.categoryName?.slice(0, MAX_CATEGORY_NAME)}
        </span>
      </div>

      <div className={classNames(s.info_wrap, s.item)}>
        <p className={s.address}>{address}</p>
        <p className={s.extra_info}>
          <span className={s.distance}>{distance}</span>
          {(headingScore || rightSpecial) && (
            <IcoLineBetween color="gray200" width={16} height={16} />
          )}
          {rightSpecial ? (
            <PoiListItemSpecialContent items={rightSpecial} />
          ) : (
            <>{headingScore && <span className={s.count}>{headingScore}대 가는중</span>}</>
          )}
          {!!poiData.special?.reviewCount && (
            <>
              <i className={s.divider}>
                <IcoLineBetween color="gray200" width={16} height={16} />
              </i>
              <div className={s.review_wrap}>
                <div className={s.review_info}>
                  <span className={s.text}>리뷰</span>
                  <span className={s.review_count}>{setComma(poiData.special.reviewCount)}</span>
                </div>
              </div>
            </>
          )}
        </p>
      </div>
      {bottomSpecial && (
        <p className={s.special_info}>
          <PoiListItemSpecialContent items={bottomSpecial} />
        </p>
      )}
      <div
        className={classNames(s.special_action, {
          [s.is_hide]: tags.length < 1 && !coupon && images.length < 1,
        })}
      >
        {tags.length > 0 && (
          <p className={s.tag_list}>
            {tags.map((t, tIdx) => (
              <span className={s.tag} key={tIdx}>
                <ItemTag {...t} />
              </span>
            ))}
          </p>
        )}
        {coupon && (
          <span className={s.coupon}>
            <PoiListItemCouponLink
              {...coupon}
              onClick={(e) => {
                sendCouponClickLog({idx, tags});
                onClickItem?.(e);
              }}
            />
          </span>
        )}
        <ul
          className={classNames(s.image_list, {[s.hide]: images.length < 1})}
          style={{'--image-count': images.length} as CSSProperties}
        >
          {images.map((image, imgIdx) => {
            return (
              <li className={s.image_wrap} key={imgIdx}>
                <Thumbnail
                  className={s.img}
                  imgSrc={getImageUrl(image, EImageSize.THUMBNAIL)}
                  alt={`${poiData.listName} 이미지`}
                />
              </li>
            );
          })}
        </ul>
      </div>

      {actionButtonType && actionButtonType !== EDetailButtonType.NONE && (
        <button
          className={s.button_wrap}
          data-action={actionButtonType}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            onClickRouteButton?.(e);
          }}
          aria-label="길찾기"
        >
          {actionButtonType === EDetailButtonType.CONFIRM ? (
            <div className={s.confirm}>확인</div>
          ) : (
            <span className={s.route}>
              <IcoRouteTurnFilled width={22} height={22} color="wbWhite" />
            </span>
          )}
        </button>
      )}
    </div>
  );
};
