export const NETWORK_TIMEOUT = 5000;
export const SUGGEST_TIMEOUT = 1000;

export enum EApiResponseCode {
  OK = '200',
  FAIL = 'FAIL', // 미정
}

export enum EApiDataCode {
  OK = '100',
  API_ERROR = '400',
  API_ERROR2 = '800',
  AUTHENTICATION = '401',
  AK_EXPIRATION = '397',
  GENERAL_FAIL = 'GENERAL_FAIL',
  FAIL = 'FAIL', // 미정
}

export const API_PATH = {
  POST_TNOW_LIST: '/api/v1/search/tlive',
  POST_PLACE_LIST: '/api/v1/search/nearby',
  POST_EV_PLACE_LIST: '/api/v1/search/evCharger',
  GET_TNOW_LINK: '/api/v1/search/link',
  POST_POPULAR_LIST: '/api/v1/search/tpopular',
  POST_TRANK_LIST: '/api/v1/search/t-rank',
  GET_POS_LIST: '/api/v1/search/pos',
  PERSONAL: '/api/v1/personal',
  BANNER: '/api/v1/search/nearby/banner',
  GET_REVERSE_GEOCODE: '/api/v1/reverseGeo',
  GET_AREA_INFO: '/api/v1/areaInfo',

  POST_SEARCH_LIST: '/api/v2/search/total',
  GET_SEARCH_ADDRESS: '/api/v1/search/area',
  GET_FAVORITE_POI: '/api/v1/favorites/poi',
  GET_FAVORITE_ROUTE: '/api/v1/favorites/route',
  POST_SEARCH_MAIN_BANNER: '/api/v1/search/main/banner',
  POST_SEARCH_TOTAL_BANNER: '/api/v1/search/total/banner',
  POST_SEARCH_MAIN_RECOMMEND: '/api/v1/search/main/recommend',

  POST_SERACH_RECOMMEND: '/api/v1/search/recommend',
  POST_THEME: '/api/v1/theme',

  POST_DELETE_DESTINATION: '/api/v1/deleteDestination',

  GET_SEARCH_FESTIVAL: '/api/v1/search/festival',
  GET_DISCOVERY_REGION_LIST: '/api/v1/search/area',
};

export const POI_API_PATH = {
  GET_POI_BASIC: '/api/v2/poi/basic',
};

export const SUGGEST_API_PATH = {
  GET_SUGGEST: '/multi/suggest',
};

export const FRONTMAN_API_PATH = {
  GET_PUBLIC_TRANS_ARRIVAL: '/tardis-bis/v2/station/arrival',
  GET_USER_DATA: '/flerken/api/v1/user-data',
  DELETE_PUBLIC_TRANS: '/flerken/api/v1/public-transport-recent/$userKey',
  GET_PUBLIC_TRANS_FAVORITE: '/tardis-favorite/v1/favorite/$userKey',

  POST_AROUND_ME_LIST: '/poi-search/api/v1/search/place/around-me',
  GET_DISCOVERY_WEEKEND_LIST: '/poi-web/api/v1/place/weekend',
  GET_DISCOVERY_SIMILAR_LIST: '/poi-web/api/v1/place/similar',
  GET_DISCOVERY_DELICIOUS_LIST: '/poi-web/api/v1/place/delicious',
  GET_DISCOVERY_TRIP_LIST: '/poi-web/api/v1/place/trip',

  GET_DISCOVERY_WEEKEND_LIST_V2: '/poi-web/api/v2/place/weekend',
  GET_DISCOVERY_SIMILAR_LIST_V2: '/poi-web/api/v2/place/similar',

  GET_SEARCH_FESTIVAL: '/poi-search/api/v1/search/festival',
  GET_SEARCH_FESTIVAL_ADVERTISE: '/poi-search/api/v1/search/festival/advertise',
};

export const PENDING_STATE = {
  loading: true,
  loaded: false,
};

export const FULFILLED_STATE = {
  loading: false,
  loaded: true,
  error: undefined,
};

export const REJECTED_STATE = {
  loading: false,
  loaded: true,
};
