import {useCallback, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';

import {ELocationActionId} from 'constant/Log';
import {TLonLat} from 'types/Map';

import useReverseGeocoding from 'hooks/useReverseGeocoding';
import useLogger from 'hooks/useLogger';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import {getAddressBySejongRule} from 'utils/general';

import {ReactComponent as IconReload} from 'resource/images/@tmds_basic/ico_reload.svg';
import {DetailActionButton} from 'components/DetailActionButton';

import s from 'styles/components/PlaceRefreshButton.module.scss';
import actions from 'ducks/actions';

type TProps = {
  onClickRefresh?: (center: TLonLat) => void;
  isStaticLabel?: boolean;
};

const DEFAULT_TEXT = '현 지도에서 재검색';

const RegionLabel = ({region}: {region?: Nullable<string>}) => {
  const refreshTailText = useAppSelector((state) => state.userInteraction.drawerRefreshTailText);
  return region ? (
    <>
      <span className={s.region}>{region}</span>
      <span className={s.guide}>{refreshTailText || '에서 재검색'}</span>
    </>
  ) : (
    <span className={s.static_label}>{DEFAULT_TEXT}</span>
  );
};

export const PlaceRefreshButton = ({onClickRefresh, isStaticLabel}: TProps) => {
  const dispatch = useAppDispatch();
  const {lat, lon} = useAppSelector(
    (state) => state.map.lastCachedCenter || {lat: undefined, lon: undefined}
  );
  const {queries} = useParseQueryLocation();
  const {sendClickLog} = useLogger();

  const [region, setRegion] = useState<Nullable<string>>(null);
  const handleClickRefresh = useCallback(() => {
    if (lat && lon) {
      onClickRefresh?.({lat, lon});
    }

    sendClickLog(
      ELocationActionId.RE_SEARCH,
      {search_query: queries.searchQuery},
      {includeTicketId: true}
    );
  }, [onClickRefresh, lat, lon, sendClickLog, queries.searchQuery]);

  const result = useReverseGeocoding({lat, lon, isEnable: !isStaticLabel});

  useEffect(() => {
    if (result.loaded && result.error) {
      setRegion(null);
      dispatch(actions.map.setLastCachedCenterGeoInfo(null));
    }

    if (result.loaded && result.data) {
      const address = getAddressBySejongRule(result.data);

      setRegion(address);
      dispatch(
        actions.map.setLastCachedCenterGeoInfo({
          regionName1: result.data.regionName1,
          regionName2: result.data.regionName2,
          regionName3: result.data.regionName3,
          regionCode: result.data.legalDongCode,
        })
      );
    }
  }, [dispatch, result]);

  if (isStaticLabel) {
    return (
      <div className={s.current_position_search}>
        <DetailActionButton onClick={handleClickRefresh} className={s.action_button}>
          <span className={s.static_label}>
            <IconReload />
            <span>{DEFAULT_TEXT}</span>
          </span>
        </DetailActionButton>
      </div>
    );
  }

  if (!region) {
    return null;
  }

  return (
    <div className={s.current_position_search}>
      <DetailActionButton onClick={handleClickRefresh} className={s.action_button}>
        <RegionLabel region={region} />
      </DetailActionButton>
    </div>
  );
};

export default PlaceRefreshButton;
