import {Route, Routes} from 'react-router-dom';
import {TRouter} from 'types/Router';

const renderNestedRouter = (routes: TRouter[]) => {
  return (
    <Routes>
      {routes.map((route, routeIndex) => {
        const Component = route.component;

        if (!route.path) {
          return (
            <Route
              key={`${routeIndex}-default`}
              path="*"
              element={<Component routes={route.routes} />}
            />
          );
        }

        if (Array.isArray(route.path)) {
          return route.path.map((path, pathIndex) => (
            <Route
              key={`${routeIndex}-${pathIndex}`}
              path={path}
              element={<Component routes={route.routes} />}
            />
          ));
        }

        return (
          <Route
            key={`${routeIndex}-single`}
            path={route.path}
            element={<Component routes={route.routes} />}
          />
        );
      })}
    </Routes>
  );
};

export default renderNestedRouter;
