import {setComma} from '../../utils/formatter';
import {IcStarFill} from '../@tmds/icons/v1.2/IcStarFill';
import {ReactComponent as IconDotBetween} from 'resource/images/ico_dot_between.svg';

import s from '../../styles/components/search/SearchPoiItemInfo.module.scss';
import {TReviewCountInfo} from 'ducks/rank/types';

type TProps = {
  starPoint?: number | null;
  reviewCountInfo?: TReviewCountInfo | null;
  category?: string;
};

const SearchPoiItemInfo = (props: TProps) => {
  const {starPoint, reviewCountInfo, category} = props;

  if (!starPoint && !reviewCountInfo?.totalReviewCount && !category) {
    return null;
  }

  const isViewStarPoint = !!(
    reviewCountInfo?.tmapReviewCount &&
    reviewCountInfo.tmapReviewCount >= 1 &&
    !!starPoint
  );

  return (
    <div className={s.wrap}>
      {isViewStarPoint && (
        <em className={s.star_point}>
          <IcStarFill width={14} height={14} color={'tmobiTeal400'} /> {starPoint}
        </em>
      )}
      {!!reviewCountInfo?.totalReviewCount && (
        <span className={s.review}>
          {isViewStarPoint
            ? `(${setComma(reviewCountInfo.totalReviewCount)})`
            : `리뷰 ${setComma(reviewCountInfo.totalReviewCount)}`}
        </span>
      )}
      {category && (
        <>
          {isViewStarPoint || reviewCountInfo?.totalReviewCount ? (
            <>
              <IconDotBetween width={12} height={12} className={s.dot} />
              <span className={s.info}>{category}</span>
            </>
          ) : (
            <span className={s.info}>{category}</span>
          )}
        </>
      )}
    </div>
  );
};

export default SearchPoiItemInfo;
