import {useCallback} from 'react';
import TMapSender, {
  ECallbackKeys,
  TmapAppScheme,
  TMapReceiver,
  TPoiDataJson,
} from '@lcc/tmap-inapp';
import {EAppRequestMode} from 'types/App';
import ua from 'utils/uaParser';
import {isOverNewFavoriteVersion, isPublicTransVersion} from 'utils/tmapUtils';
import {APP_VERSION_GUIDE_TEXT} from 'constant/Text';
import {parsePoiInfoToNavInfo} from 'utils/search';
import {useAppDispatch} from 'ducks/hooks';
import {fetchUserData} from 'ducks/userInfo/slice';
import {customEventEmitter, ECustomEvent} from 'utils/customEventEmitter';
import actions from 'ducks/actions';

type TOpenBusDetailProps = {
  name: string;
  poiId: string;
  centerX: string | number;
  centerY: string | number;
  stationId: string;
  reqMode?: EAppRequestMode;
};

export type TGetFavoriteGroupResponse = {
  result: boolean;
  poiDataList: TPoiDataJson[];
};

export type TGetDisplayPoiFavoriteItemsCount = {
  result: boolean;
  count: number;
};

const useAppScheme = () => {
  const dispatch = useAppDispatch();

  const openBusStationDetail = useCallback(
    ({name, poiId, centerX, centerY, stationId, reqMode}: TOpenBusDetailProps) => {
      if (ua.isInApp && !isPublicTransVersion()) {
        TMapSender.makeToast(APP_VERSION_GUIDE_TEXT);
        return;
      }

      TMapSender.openBusStationDetail({
        name,
        poiId,
        skX: Number(centerX),
        skY: Number(centerY),
        stationId: `${stationId}` || '',
        // @ts-ignore
        reqMode: reqMode || EAppRequestMode.MAIN,
      });
    },
    []
  );

  const toggleFavorite = useCallback(
    (poiDataJson: TPoiDataJson) => {
      return new Promise((resolve, reject) => {
        if (poiDataJson) {
          const isPublicTransPoi = poiDataJson.stationId;

          if (!isPublicTransVersion() && isPublicTransPoi) {
            TMapSender.makeToast(APP_VERSION_GUIDE_TEXT);
            reject();
            return;
          }

          if (isOverNewFavoriteVersion() && !isPublicTransPoi) {
            TMapReceiver.setCallback(ECallbackKeys.OPEN_FAVORITE_GROUP_POPUP, (isOn) => {
              resolve(isOn);
              customEventEmitter.emit(ECustomEvent.POI_FAVORITE_CHANGED, isOn, poiDataJson);

              // https://tmobi.atlassian.net/browse/CLIENTQA-5859
              if (ua.isIos) {
                dispatch(actions.userInteraction.setRefreshAdKey(Date.now()));
              }
            });
            TMapSender.openFavoriteGroupPopup([poiDataJson]);
          } else {
            TMapReceiver.setCallback(ECallbackKeys.FAVORITE, (isOn) => {
              resolve(isOn);
              customEventEmitter.emit(ECustomEvent.POI_FAVORITE_CHANGED, isOn, poiDataJson);
            });

            TMapSender.toggleFavorite({
              ...poiDataJson,
              ...(poiDataJson && {
                stationId: `${poiDataJson.stationId}`,
                publicTransportType: poiDataJson.publicTransportType,
              }),
            });
          }
        } else {
          reject();
        }
      });
    },
    [dispatch]
  );

  const toggleSearchFavorite = useCallback(
    (
      poiData: Omit<TPoiDataJson, 'navX' | 'navY' | 'centerX' | 'centerY' | 'tel' | 'navSeq'> & {
        navX?: string | number;
        navY?: string | number;
        centerX?: string | number;
        centerY?: string | number;
        tel?: string;
        navSeq?: string;
      }
    ) => {
      const isNewVersion = isOverNewFavoriteVersion();
      const isPublicTransPoi = !!poiData.stationId && !!poiData.publicTransportType;

      return toggleFavorite(parsePoiInfoToNavInfo(poiData)).then((isOn) => {
        if (!isNewVersion || isPublicTransPoi) {
          dispatch(fetchUserData());
        }
        return {isOn: !!isOn, isNewVersion} as {isOn: boolean; isNewVersion: boolean};
      });
    },
    [toggleFavorite, dispatch]
  );

  const getFavoriteGroup = useCallback((poiIdList) => {
    return TmapAppScheme.getFavoriteGroup({poiIdList});
  }, []);

  const getFavoriteState = useCallback((data) => {
    return TmapAppScheme.getFavoriteState(
      parsePoiInfoToNavInfo(data)
    ) as Promise<TGetFavoriteGroupResponse>;
  }, []);

  const getDisplayPoiFavoriteItemsCount = useCallback(() => {
    return TmapAppScheme.getDisplayPoiFavoriteItemsCount() as Promise<TGetDisplayPoiFavoriteItemsCount>;
  }, []);

  const openBusRouteDetail = useCallback((busLineId) => {
    if (ua.isInApp && !isPublicTransVersion()) {
      TMapSender.makeToast(APP_VERSION_GUIDE_TEXT);
      return;
    }

    TMapSender.openBusRouteDetail(busLineId);
  }, []);

  return {
    openBusStationDetail,
    openBusRouteDetail,
    toggleFavorite,
    toggleSearchFavorite,
    getFavoriteGroup,
    getFavoriteState,
    getDisplayPoiFavoriteItemsCount,
  };
};

export default useAppScheme;
