import {EMapStyle, EMapFontSize} from '@lcc/tmap-inapp';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TCenterOffset, TLonLat} from 'types/Map';
import {TMapContext} from 'ducks/userInfo/types';
import {
  TDebugMode,
  TLastCachedCenter,
  TMapState,
  TMapViewPort,
  TMarkerStyleConfig,
  TReverseGeoInfo,
} from './types';
import {isLocalEnv} from 'constant/Env';

const ACTION_NAME = 'map';

const initialState: TMapState = {
  userPosition: undefined,
  userPositionReverseGeoInfo: undefined,
  style: undefined,
  lastCachedCenter: undefined,
  lastCachedCenterReverseGeoInfo: undefined,
  debugMode: undefined,
  nowCenter: undefined,
  nowCenterPosTime: undefined,
  nowPitch: undefined,
  nowBearing: undefined,
  zoom: undefined,
  nowViewPort: undefined,
  fontSize: EMapFontSize.NORMAL,
  centerOffset: undefined,
  nowMarkerPosition: undefined,
  markerStyleConfig: undefined,
  saveMarkerDisplay: isLocalEnv ? true : false,
  saveClusteringDisplay: isLocalEnv ? true : false,
};

const mapSlice = createSlice({
  name: ACTION_NAME,
  initialState,
  reducers: {
    setUserPosition: (state, action: PayloadAction<TLonLat>) => {
      state.userPosition = action.payload;
    },
    setUserPositionReverseGeoInfo: (state, action: PayloadAction<Nullable<TReverseGeoInfo>>) => {
      state.userPositionReverseGeoInfo = action.payload;
    },
    setStyle: (state, action: PayloadAction<EMapStyle>) => {
      state.style = action.payload;
    },
    setLastCachedCenter: (state, action: PayloadAction<TLastCachedCenter>) => {
      state.lastCachedCenter = action.payload;
    },
    setLastCachedCenterGeoInfo: (state, action: PayloadAction<Nullable<TReverseGeoInfo>>) => {
      state.lastCachedCenterReverseGeoInfo = action.payload;
    },
    setNowCenter: (state, action: PayloadAction<TLonLat>) => {
      state.nowCenter = action.payload;
      state.nowCenterPosTime = Date.now();
    },
    resetNowCenter: (state) => {
      state.nowCenter = undefined;
      state.nowCenterPosTime = Date.now();
    },
    setDebugMode: (state, action: PayloadAction<TDebugMode>) => {
      state.debugMode = action.payload;
    },
    setZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
    setViewPort: (state, action: PayloadAction<TMapViewPort>) => {
      state.nowViewPort = action.payload;
    },
    setPitch: (state, action: PayloadAction<number>) => {
      state.nowPitch = action.payload;
    },
    setBearing: (state, action: PayloadAction<number>) => {
      state.nowBearing = action.payload;
    },
    setFontSize: (state, action: PayloadAction<EMapFontSize>) => {
      state.fontSize = action.payload;
    },
    setCenterOffset: (state, action: PayloadAction<TCenterOffset>) => {
      state.centerOffset = action.payload;
    },
    setMapContext: (state, action: PayloadAction<TMapContext>) => {
      state.nowCenter = {
        lon: action.payload.x,
        lat: action.payload.y,
      };
      state.nowPitch = action.payload.tilt;
      state.nowBearing = action.payload.rotate;
      state.zoom = action.payload.zoom;
    },
    setNowMarkerPosition: (state, action: PayloadAction<TLonLat>) => {
      state.nowMarkerPosition = action.payload;
    },
    setMarkerStyleConfig: (state, action: PayloadAction<TMarkerStyleConfig>) => {
      state.markerStyleConfig = action.payload;
    },
    setSaveMarkerDisplay: (state, action: PayloadAction<boolean>) => {
      state.saveMarkerDisplay = action.payload;
    },
    setSaveClusteringDisplay: (state, action: PayloadAction<boolean>) => {
      state.saveClusteringDisplay = action.payload;
    },
  },
});

export default mapSlice;
