import {useCallback, useMemo} from 'react';
import {useAppSelector} from 'ducks/hooks';
import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import {TRecommendKeywordItem} from 'ducks/remote/type';
import {sendSearchClickLog} from 'utils/logManager';

import ImgAdTagSrc from 'resource/images/AD_Tag.png';

import s from 'styles/components/search/SearchRecommendKeyword.module.scss';

const NUMBER_TO_DIVIDE = 5;

const SearchRecommendKeyword = () => {
  const remoteConfig = useAppSelector((state) => state.remote);

  const {moveToSearch} = useMoveToTarget({from: EFromType.RECENT});

  const keywords = useMemo(() => {
    const data = remoteConfig.searchRecommendKeyword;
    const adKeyWord = data.filter((item) => item.type === 'ad').slice(0, 1);
    const searchKeyWord = data.filter((item) => item.type === 'search').slice(0, 10);
    const arr: TRecommendKeywordItem[][] = [];

    for (let i = 0; i < searchKeyWord.length; i += NUMBER_TO_DIVIDE) {
      arr.push(searchKeyWord.slice(i, i + NUMBER_TO_DIVIDE));
    }

    if (adKeyWord.length > 0) {
      arr[0].unshift(adKeyWord[0]);
    }
    return arr;
  }, [remoteConfig]);

  const handleClick = useCallback(
    (index: number, item: TRecommendKeywordItem) => {
      sendSearchClickLog('tap.recommend_keyword', {
        index,
        ad: item.type === 'ad',
        keyword: item.keyword,
      });
      moveToSearch(item.keyword);
    },
    [moveToSearch]
  );

  let keywordIndex = 0;

  if ((remoteConfig.lastUpdateTime || 0) < 1) {
    return (
      <div className={s.skeleton}>
        <div className={s.list}>
          <span className={s.item} />
          <span className={s.item} />
          <span className={s.item} />
        </div>
      </div>
    );
  }

  if (!keywords.length) {
    return null;
  }

  return (
    <div className={s.wrap}>
      {keywords.map((list, index) => {
        return (
          <div key={index} className={s.row}>
            {list?.map((item, sIndex) => {
              const logIndex = keywordIndex++;
              return (
                <button
                  key={sIndex}
                  type="button"
                  className={s.item}
                  onClick={() => handleClick(logIndex, item)}
                >
                  {item.type === 'ad' && (
                    <img src={ImgAdTagSrc} width={29} height={18} alt="광고" />
                  )}
                  {item.keyword.length > 10 ? `${item.keyword.slice(0, 10)}...` : item.keyword}
                </button>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default SearchRecommendKeyword;
