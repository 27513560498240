import {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import actions from 'ducks/actions';

import {useAppDispatch} from 'ducks/hooks';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import {EHistoryStack} from 'ducks/userInteraction/types';

export const useRecentTabMode = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const {location: editLocation, originQueries: currentQueries} = useParseQueryLocation({
    edit: true,
  });

  const isEdit = useMemo(() => currentQueries.edit === 'true', [currentQueries]);

  const enterEditMode = useCallback(() => {
    navigate(editLocation);
    dispatch(actions.userInteraction.pushHistoryStack(EHistoryStack.EDIT_RECENT_TAB));
  }, [dispatch, navigate, editLocation]);

  const exitEditMode = useCallback(() => {
    navigate(-1);
    dispatch(actions.userInteraction.popHistoryStack());
  }, [dispatch, navigate]);

  return {
    isEdit,
    enterEditMode,
    exitEditMode,
  };
};
