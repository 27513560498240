import {useEffect, useState} from 'react';
import Thumbnail from './Thumbnail';
import {getStaticMapUrl} from 'utils/staticMap';
import {ECoordType} from '@lcc/tmap-static-map';
import classNames from 'classnames';
import {ReactComponent as IcoRedDotMarker} from 'resource/images/mapmarker_place_normal_day.svg';
import s from 'styles/components/FestivalThumbnail.module.scss';
import {getImageUrl} from 'utils/url';
import {EImageSize} from 'types/Image';

type TProps = {
  imageUrl: Nullable<string>;
  width: number | string;
  height: number | string;
  wgs84X?: number;
  wgs84Y?: number;
  zoom?: number;
  alt?: string;
  status?: string;
  className?: string;
};

const FestivalThumbnail = ({
  imageUrl,
  width,
  height,
  wgs84X,
  wgs84Y,
  zoom = 13,
  alt,
  status,
  className,
}: TProps) => {
  const [isStaticMapThumbnail, setIsStaticMapThumbnail] = useState<boolean>(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!imageUrl && !!wgs84X && !!wgs84Y) {
      setThumbnailUrl(
        getStaticMapUrl({
          center: {lon: wgs84X, lat: wgs84Y},
          zoom,
          markers: [],
          width: typeof width === 'number' ? width : undefined,
          height: typeof height === 'number' ? height : undefined,
          coordType: ECoordType.WGS84GEO,
        }) as string
      );
      setIsStaticMapThumbnail(true);
    } else {
      setThumbnailUrl(getImageUrl(imageUrl || '', EImageSize.THUMBNAIL));
      setIsStaticMapThumbnail(false);
    }
  }, [imageUrl, wgs84X, wgs84Y, width, height, zoom, alt]);

  return thumbnailUrl !== null ? (
    <div className={classNames(s.thumbnail_wrap, className)}>
      <Thumbnail imgSrc={thumbnailUrl} width={width} height={height} alt={alt} />
      {!!status?.length && <div className={s.status}>{status}</div>}
      {isStaticMapThumbnail && <IcoRedDotMarker className={s.red_dot} />}
    </div>
  ) : null;
};

export default FestivalThumbnail;
