import {AdBanner} from 'components/AdBanner';
import {ReloadResume} from 'components/ReloadResume';
import {
  SEARCH_BANNER_AD_STEP,
  SEARCH_RESULT_BANNER_PROVIDER_CONFIG,
  isBannerTestEnv,
  TEST_BANNER_PROVIDER_CONFIG,
  EAdCode,
} from 'constant/Ads';
import {useAppSelector} from 'ducks/hooks';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';

type TProps = {
  adCode: EAdCode;
  options?: Record<string, any>;
};

const SearchResultAdBanner = ({adCode, options}: TProps) => {
  const {queries} = useParseQueryLocation();
  const isLogInitialized = useAppSelector((state) => state.log.isInitialize);

  return (
    <ReloadResume minHeight={56}>
      <AdBanner
        isLogInitialized={isLogInitialized}
        visibleLandscape={true}
        adCode={adCode}
        adTypeStep={SEARCH_BANNER_AD_STEP}
        adTypeOption={{
          ...SEARCH_RESULT_BANNER_PROVIDER_CONFIG,
          ...options,
          ...(isBannerTestEnv ? TEST_BANNER_PROVIDER_CONFIG : {}),
        }}
        logData={{
          custom: {search_query: queries.search_query},
          includeTicketId: true,
        }}
      />
    </ReloadResume>
  );
};

export default SearchResultAdBanner;
