import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {BrowserRouter} from 'react-router-dom';

import actions from 'ducks/actions';
import useWindowSize from 'hooks/useWindowSize';
import renderNestedRouter from 'utils/renderNestedRouter';
import routes from './routes';

import '@egjs/react-flicking/dist/flicking.css';

const App = () => {
  const dispatch = useAppDispatch();
  const nowWindowSize = useWindowSize();
  const {resumeKey} = useAppSelector((state) => state.userInteraction);

  useEffect(() => {
    dispatch(
      actions.layout.windowResize({width: nowWindowSize.width, height: nowWindowSize.height})
    );

    if (nowWindowSize.isLandscape !== null) {
      dispatch(actions.layout.setLandscape(nowWindowSize.isLandscape));
    }
  }, [dispatch, nowWindowSize]);

  /**
   * 백그라운드 -> 포어그라운드 전환 시 간헐적 백화현상으로 강제 repaint. (== resume시 repaint)
   * https://tmobi.atlassian.net/browse/LOCALQA-568
   * https://tmobi.atlassian.net/browse/TMAPWEB-3065
   */
  useEffect(() => {
    const bodyEl = document.querySelector('body') as HTMLBodyElement;
    bodyEl.style.transform = 'translate3d(0, 0, 0)';
    setTimeout(() => (bodyEl.style.transform = ''), 50);
  }, [resumeKey]);

  return <BrowserRouter>{renderNestedRouter(routes)}</BrowserRouter>;
};

export default App;
