import {useCallback, useMemo, useRef} from 'react';
import {
  LANDSCAPE_LIST_MARGIN,
  LANDSCAPE_LIST_WIDTH,
  MAX_MARKER_HEIGHT,
  MAX_MARKER_TITLE_HEIGHT,
  MAX_MARKER_WIDTH,
  TITLE_AREA_HEIGHT,
} from 'constant/Size';
import {useAppSelector} from 'ducks/hooks';
import {EOrientation} from 'types/Device';
import {TBoundsPadding} from 'types/Map';
import {getBoundsPadding} from 'utils/general';
import {TListDrawerResult} from 'types/ListDrawer';

type TProps = {
  offsetTop?: number;
};

const useMapOffset = ({offsetTop = 0}: TProps = {}) => {
  const {isHybrid, isLandscape, statusBarHeight, searchBarHeight} = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
    isLandscape: state.layout.appSize.isLandscape,
    statusBarHeight: state.layout.appSize.statusBarHeight,
    searchBarHeight: state.layout.appSize.searchBarHeight,
  }));
  const refBoundsPadding = useRef<{[key in EOrientation]?: TBoundsPadding}>({});

  const centerOffset = useMemo(() => {
    return {
      x: isLandscape ? -((LANDSCAPE_LIST_WIDTH + LANDSCAPE_LIST_MARGIN) / 2) : 0,
      y: isLandscape
        ? ((statusBarHeight || 0) + offsetTop) / 2
        : statusBarHeight
          ? statusBarHeight / 2
          : 0,
    };
  }, [isLandscape, statusBarHeight, offsetTop]);

  const getBoundsPaddingAndOffset = useCallback(
    (
      drawerProps: TListDrawerResult,
      {headerHeight, maxMarkerHeight, maxMarkerWidth, maxMarkerTitleHeight, bottomPadding}: any = {
        headerHeight: 0,
        maxMarkerHeight: MAX_MARKER_HEIGHT,
        maxMarkerWidth: MAX_MARKER_WIDTH,
        maxMarkerTitleHeight: MAX_MARKER_TITLE_HEIGHT,
        bottomPadding: 0,
      }
    ) => {
      const orientation = isLandscape ? EOrientation.LANDSCAPE : EOrientation.PORTRAIT;
      const keyName = `${orientation}${isHybrid ? '_hybrid' : ''}`;

      const boundsPadding: TBoundsPadding | undefined =
        refBoundsPadding.current[keyName] ||
        getBoundsPadding({
          isLandscape,
          mapPosition: drawerProps?.position.map,
          listPosition: drawerProps?.size.centerPoint,
          headerHeight,
          statusBarHeight,
          searchBarHeight: isHybrid ? searchBarHeight : 0,
          maxMarkerHeight,
          maxMarkerWidth,
          maxMarkerTitleHeight,
          bottomPadding,
        });

      refBoundsPadding.current[keyName] = boundsPadding;

      return {
        boundsPadding,
        boundOffset: boundsPadding
          ? {
              x: 0,
              y: isLandscape
                ? (MAX_MARKER_TITLE_HEIGHT - MAX_MARKER_HEIGHT) / 2
                : (boundsPadding.bottom - boundsPadding.top) / 2,
            }
          : undefined,
      };
    },
    [isLandscape, statusBarHeight, isHybrid, searchBarHeight]
  );

  const getMapOffset = useCallback(
    (drawerProps: TListDrawerResult) => {
      if (isLandscape) {
        const rightPadding = LANDSCAPE_LIST_WIDTH + LANDSCAPE_LIST_MARGIN;
        return {
          top: statusBarHeight || 0,
          left: 0,
          right: rightPadding,
          bottom: 0,
        };
      } else {
        const statusBarPadding = statusBarHeight / 2 || 0;
        const topBottomPadding = TITLE_AREA_HEIGHT + (drawerProps?.size.mapTopPoint || 0);
        return {
          top: topBottomPadding + statusBarPadding,
          left: 0,
          right: 0,
          bottom: topBottomPadding - statusBarPadding,
        };
      }
    },
    [isLandscape, statusBarHeight]
  );

  return {
    centerOffset,
    getBoundsPaddingAndOffset,
    getMapOffset,
  };
};

export default useMapOffset;
