import {TApiResponse, TApiStatus} from 'types/Api';
import {TSearchPoi} from 'types/App';
import {TLonLat} from 'types/Map';
import {
  ECityCode,
  EGuideSearchType,
  ESearchLocationType,
  ESortOption,
  ESuggestSearchType,
  TAddressItem,
  TBusClass,
} from 'types/Search';

export type TSearchProps = TLonLat & {
  query: string;
  sort?: ESortOption;
  locationType?: ESearchLocationType;
  userPosition?: TLonLat;
  onMap?: boolean;
  usePrevCenter?: boolean;
  collectionType?: ESearchCollectionType;
  cityCode?: ECityCode;
  categories?: ECategoryType[];
  tmapFamousYn?: boolean;
  openNowYn?: boolean;
  poiParkYn?: boolean;
  isWaitingReservation?: boolean;
  fromRecommendedYn?: boolean;
  poiAdvertiseYn?: string;
  poiAdvertisePosition?: number;
};

export type TSearchParams = TLonLat & {
  appVersion: string;
  userRealLat: number;
  userRealLon: number;
  query: string;
  searchLocationType: ESearchLocationType;
  page: number;
  size: number;
  sort: ESortOption;
  guideSearchType?: string;
  geoPolygon?: string;
  collectionType?: ESearchCollectionType;
  cityCode?: ECityCode;
  categories?: ECategoryType[];
  tmapFamousYn?: string;
  openNowYn?: string;
  poiParkYn?: string;
  featureOr?: string;
  fromRecommendedYn?: string;
  poiAdvertiseYn: string;
  poiAdvertisePosition?: number;
};

export enum ESearchCollectionType {
  POI = 'poi',
  BUS_LINE = 'bus_line',
  BUS_STATION = 'bus_station',
}

export enum ECategoryType {
  POI_EVENT = 'POIEVT',
  FUDKOR = 'FUDKOR',
  FUDCHN = 'FUDCHN',
  FUDWES = 'FUDWES',
  FUDJPN = 'FUDJPN',
  CAFEALL = 'CAFEALL',
  FUDBAR = 'FUDBAR',
}

export type TBusLineItem = {
  poiType: ESearchCollectionType.BUS_LINE;
  pkey: string;
  busLineId: string;
  busLineName: string;
  busStartStation: string;
  busEndStation: string;
  busFirstTime: string;
  busLastTime: string;
  busClass: Nullable<TBusClass>;
  cityName: string;
  cityCode: ECityCode;
};

export type TBusStationItem = {
  poiType: ESearchCollectionType.BUS_STATION;
  pkey: string;
  busStationId: string;
  busStationName: string;
  busStationDisplayName: string;
  busToStation: string;
  busClasses: TBusClass[];
  distance: Nullable<number>;
  userRealDistance: Nullable<number>;
  wgs84CenterX: Nullable<number>;
  wgs84CenterY: Nullable<number>;
  lcdName: string;
  mcdName: string;
  scdName: string;
  dcdName: Nullable<string>;
  centerX: number;
  centerY: number;
} & {
  listId: string;
  listName: string;
};

type TSearchItemType = TSearchPoi | TBusLineItem | TBusStationItem;

type TSearchPoiResponse = {
  docs: TSearchPoi[];
  guideSearchDisplayText: string;
  guideSearchType: EGuideSearchType;
  userQuery: string;
  guideSearchKeyword: string;
  suggestReplaceKeyword: string;
  suggestSearchType: ESuggestSearchType;
};

type TSearchBusLineResponse = {
  areaCityCodes: ECityCode[];
  docs: TBusLineItem[];
  totalCount: number;
  userQuery: string;
};

type TSearchBusStationResponse = {
  docs: TBusStationItem[];
  totalCount: number;
  userQuery: string;
};

export type TSearchApiResponse = {
  collectionType: ESearchCollectionType;
  poi: TSearchPoiResponse;
  busLine: TSearchBusLineResponse;
  busStation: TSearchBusStationResponse;
  ltrYn: Nullable<boolean>;
} & {
  totalCount: number;
  list: TSearchItemType[];
};

export type TAddressSearch = {
  cate1: TAddressItem;
  cate2: TAddressItem;
  cate3: TAddressItem;
  isRoadType: boolean;
  detail: string;
};

export type TSearchPayload = TApiResponse<TSearchApiResponse>;

export type TSearchState = TApiStatus<TSearchApiResponse> & {
  sort: ESortOption;
  nowPage: number;
  maxPage: number;
  lastParam?: TSearchParams;
  searchList: TSearchItemType[];
  locationType: ESearchLocationType;
  guideSearchType?: string;
  prevGuideSearchType?: string;
  addressSearch?: TAddressSearch;
  sortCityCode?: ECityCode;
  sortCityCodeList: ECityCode[];
  tmapFamousYn?: boolean;
  openNowYn?: boolean;
  poiParkYn?: boolean;
  isWaitingReservation?: boolean;
  categories?: ECategoryType[];
  fromRecommendedYn?: boolean;
};
