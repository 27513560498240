import {useEffect, useState} from 'react';
import classNames from 'classnames';
import {ESubwayExpressType, TSubwayArrival, TSubwaySchedule} from 'types/PublicTrans';
import useInterval from 'hooks/useInterval';
import {getSafeDateFormat, parseSeconds} from 'utils/date';

import s from 'styles/components/CalloutInfoSubwayList.module.scss';

type TProps = {
  list: TSubwayArrival[] | TSubwaySchedule[];
  loading: boolean;
  loaded: boolean;
  error?: any;
  isRealTime: boolean;
  isLast: boolean;
};

const EXPRESS_TEXT: Record<ESubwayExpressType, string> = {
  [ESubwayExpressType.SUBWAY_GENERAL]: '',
  [ESubwayExpressType.SUBWAY_EXPRESS]: '(급)',
  [ESubwayExpressType.SUBWAY_SUPER_EXPRESS]: '(특)',
};

// https://tmobi.atlassian.net/wiki/spaces/TMAPONE/pages/290180428
// 도착시간이 999분 이상 남으면 미노출
const MAX_REMAIN_TIME = 999 * 60;

type TSubwayInfo = {
  item: TSubwayArrival;
  isRealTime: boolean;
  isLast: boolean;
};

const TIME_DELAY = 1000;

const formatSeconds = (sec: number) => {
  const {hours = 0, minutes = 0, seconds = 0} = parseSeconds(sec);
  const time = new Date(0, 0, 0, hours, minutes, seconds);

  if (hours > 0) {
    return getSafeDateFormat(time, minutes === 0 ? 'H시' : 'H시 m분');
  }

  if (minutes < 1) {
    return '곧 도착';
  }

  return getSafeDateFormat(time, seconds === 0 ? 'm분' : 'm분 s초');
};

const SubwayItem = ({item, isRealTime, isLast}: TSubwayInfo) => {
  const [remainSeconds, setRemainSeconds] = useState(0);
  const expressText = EXPRESS_TEXT[item.expressType];
  const interval = useInterval(() => {
    setRemainSeconds((prev) => prev - 1);
  }, TIME_DELAY);

  useEffect(() => {
    if (item.remainSeconds) {
      setRemainSeconds(item.remainSeconds);
      interval.start();
    } else {
      interval.stop();
    }
  }, [item.remainSeconds]);

  useEffect(() => {
    return () => {
      interval.stop();
    };
  }, []);

  if (isLast) {
    return (
      <li className={s.item}>
        <span className={classNames(s.title)}>종점</span>
      </li>
    );
  }

  if (item.close) {
    return (
      <li className={s.item}>
        <span className={classNames(s.title, s.is_close)}>운행종료</span>
      </li>
    );
  }

  return (
    <li className={s.item}>
      <span className={s.title}>
        {expressText && <em className={s.red}>{expressText}</em>}
        {item.headSign}
      </span>
      {item.remainSeconds < MAX_REMAIN_TIME && (
        <span
          className={classNames(s.time, {
            [s.red]: isRealTime,
          })}
        >
          {item.last && <span className={s.blue}>(막)</span>}
          {item.start && <span className={s.blue}>(첫)</span>}
          {item.remainSeconds > 0 ? (formatSeconds(remainSeconds) as string) : item.arrivalText}
        </span>
      )}
    </li>
  );
};

const CalloutInfoSubwayList = ({loading, loaded, error, list, isRealTime, isLast}: TProps) => {
  if (loading) {
    return (
      <li className={s.item}>
        <span className={s.title}>조회중</span>
      </li>
    );
  }

  if ((loaded && list.length === 0) || error) {
    return (
      <li className={s.item}>
        <span className={s.title}>도착정보 없음</span>
      </li>
    );
  }

  return (
    <>
      {(list || [])
        .sort((v1, v2) => v1.remainSeconds - v2.remainSeconds)
        .map((v, i) => (
          <SubwayItem
            key={i}
            item={{
              ...v,
              expressType: v.expressCode || v.expressType,
              remainSeconds: v.remainSeconds || v.remainTimes || 0,
            }}
            isRealTime={isRealTime}
            isLast={isLast}
          />
        ))}
    </>
  );
};

export default CalloutInfoSubwayList;
