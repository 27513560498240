import {Navigate} from 'react-router-dom';

import {TRouter} from 'types/Router';
import {DevRoutes, Paths, ProtoRoutes, SearchRoutes} from 'constant/RoutePath';
import {isProdEnv} from 'constant/Env';

import CommonLayout from 'components/CommonLayout';
import LegacySearchLayout from 'components/legacySearch/LegacySearchLayout';

import ProtoPage from 'pages/ProtoPage';
import ProtoImagePage from 'pages/ProtoImagePage';
import ProtoCustomSelectPage from 'pages/ProtoCustomSelectPage';
import ProtoPubImagePage from 'pages/ProtoPubImagePage';
import ProtoBannerPage from 'pages/ProtoBannerPage';
import ProtoKeywordPage from 'pages/ProtoKeywordPage';

import DevPage from 'pages/DevPage';
import DevRedDotPage from 'pages/DevRedDotPage';

import DevHybridBridgePage from 'pages/DevHybridBridgePage';

import styles from 'styles/pages/Routes.module.scss';
import {isNewApp} from 'utils/tmapUtils';
import SearchLayout from 'components/search/SearchLayout';
import DevAppInterfacePage from 'pages/DevAppInterfacePage';

const routes: TRouter[] = [
  {
    path: SearchRoutes,
    component: (props) =>
      isNewApp() ? (
        <SearchLayout {...{...props, className: styles.search_wrap}} />
      ) : (
        <LegacySearchLayout {...{...props, className: styles.search_wrap}} />
      ),
    routes: [
      {
        component: () => <Navigate to={Paths.SearchMain} replace />,
      },
    ],
  },
  {
    path: ProtoRoutes,
    component: (props) =>
      isProdEnv ? (
        // TODO: 에러 페이지 랜딩
        <Navigate to={Paths.SearchHybridMain} replace />
      ) : (
        <CommonLayout {...{...props, className: styles.place_wrap}} />
      ),
    routes: [
      {
        path: Paths.Proto,
        component: ProtoPage,
      },
      {
        title: '검색 > 배너',
        path: Paths.ProtoBanner,
        component: ProtoBannerPage,
      },
      {
        title: '검색 > 키워드 광고',
        path: Paths.ProtoKeyword,
        component: ProtoKeywordPage,
      },
      {
        title: '티지금 > 이미지',
        path: Paths.ProtoImage,
        component: ProtoImagePage,
      },
      {
        title: '티지금 > 커스텀셀렉트박스',
        path: Paths.ProtoCustomSelect,
        component: ProtoCustomSelectPage,
      },
      {
        title: '티지금 > Static 이미지',
        path: Paths.ProtoPubImage,
        component: ProtoPubImagePage,
      },

      {
        component: () => <Navigate to={Paths.Proto} replace />,
      },
    ],
  },

  {
    path: DevRoutes,
    component: (props) =>
      isProdEnv ? (
        // TODO: 에러 페이지 랜딩
        <Navigate to={Paths.SearchHybridMain} replace />
      ) : (
        <CommonLayout {...{...props, className: styles.place_wrap}} />
      ),
    routes: [
      {
        path: Paths.Dev,
        component: DevPage,
      },
      {
        title: '티지금 > 레드닷',
        path: Paths.DevRedDot,
        component: DevRedDotPage,
      },
      {
        title: '검색 > 하이브리드',
        path: Paths.DevHybridBridge,
        component: DevHybridBridgePage,
      },
      {
        title: '검색 > 앱인터페이스',
        path: Paths.DevAppInterface,
        component: DevAppInterfacePage,
      },
      {
        component: () => <Navigate to={Paths.Dev} replace />,
      },
    ],
  },

  {
    component: () => <Navigate to={Paths.SearchMain} replace />,
  },
];

export default routes;
