import {useCallback, useRef} from 'react';
import classNames from 'classnames';
import Thumbnail from 'components/Thumbnail';
import {TPoiImageInfo} from 'types/App';
import {EImageSize} from 'types/Image';
import {getImageUrl} from 'utils/url';

import s from 'styles/components/search/SearchRecommendImage.module.scss';

const MIN_IMG_COUNT = 3;

type TProps = {
  images: TPoiImageInfo[];
  listName?: string;
  onScroll?: (e) => void;
};

const SearchRecommendImage = ({images, listName, onScroll}: TProps) => {
  const refIsScrollStart = useRef(false);

  const handleScroll = useCallback(
    (e) => {
      if (refIsScrollStart.current === false) {
        refIsScrollStart.current = true;
        onScroll?.(e);
      }
    },
    [onScroll]
  );

  if (images.length < MIN_IMG_COUNT) {
    return null;
  }

  return (
    <div
      className={classNames(s.wrap, {
        [s.is_scroll]: images.length > MIN_IMG_COUNT,
      })}
      style={{'--img-count': images.length} as React.CSSProperties}
      onScroll={handleScroll}
    >
      <div className={s.image_list}>
        {images.map((image, imgIdx) => (
          <Thumbnail
            key={imgIdx}
            imgSrc={getImageUrl(image, EImageSize.THUMBNAIL)}
            className={s.img}
            alt={`${listName} 이미지`}
          />
        ))}
      </div>
    </div>
  );
};

export default SearchRecommendImage;
