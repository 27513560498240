import {useCallback} from 'react';
import InView from 'react-intersection-observer';
import classNames from 'classnames';
import useMoveToTarget from 'hooks/useMoveToTarget';
import {sendSearchClickLog} from 'utils/logManager';
import {TAddressItem} from 'types/Search';
import {useAppSelector} from 'ducks/hooks';

import {ReactComponent as IconDotBetween} from 'resource/images/ico_dot_between.svg';
import {IcArrowRightBold} from 'components/@tmds/icons/v1.2/IcArrowRightBold';

import s from 'styles/components/search/SearchRecommendFestival.module.scss';
import {TFestivalItem} from 'ducks/festival/types';
import FestivalThumbnail from 'components/FestivalThumbnail';

type TProps = {
  region?: TAddressItem;
  isVerticalType?: boolean;
};

const SearchRecommendFestivalList = ({region, isVerticalType}: TProps) => {
  const {list, reverseGeoInfo} = useAppSelector((state) => ({
    list: state.festival.data.list,
    reverseGeoInfo: state.searchRecommend.reverseGeoInfo,
  }));

  const {moveToDetail} = useMoveToTarget();

  const handleClick = useCallback(
    (index: number, item: TFestivalItem) => {
      sendSearchClickLog('tap.festival_poi', {
        index,
        poiname: item.name,
        pkey: item.pkey,
        poi_region: `${item.areaDepth1Name} ${item.areaDepth2Name}`,
        regionfilter: isVerticalType ? region?.areaName : undefined,
        region_1D: reverseGeoInfo.regionName1,
        region_2D: reverseGeoInfo.regionName2,
        region_3D: reverseGeoInfo.regionName3,
      });
      moveToDetail(item);
    },
    [region, reverseGeoInfo, isVerticalType, moveToDetail]
  );

  if (list === undefined) {
    return null;
  }

  return (
    <>
      {list.length === 0 && isVerticalType ? (
        <p className={s.no_data}>축제가 없습니다.</p>
      ) : (
        <ul className={classNames(s.wrap, {[s.vertical]: isVerticalType})}>
          {list.map((item, index) => {
            return (
              <InView as="li" key={`${item.poiId}_${index}`} className={s.cell} threshold={0.5}>
                <button type="button" className={s.item} onClick={() => handleClick(index, item)}>
                  <FestivalThumbnail
                    imageUrl={item.imageUrl}
                    width={isVerticalType ? 80 : '100%'}
                    height={isVerticalType ? 80 : 114}
                    wgs84X={item.wgs84X}
                    wgs84Y={item.wgs84Y}
                    alt={`${item.name} 이미지`}
                    status={item.status}
                    className={s.thumbnail}
                  />
                  <div className={s.info}>
                    <em className={s.location}>
                      {item.areaDepth1Name} {item.areaDepth2Name}
                    </em>
                    <strong className={s.name}>{item.name}</strong>
                    {item.goingCount > 4 && isVerticalType && (
                      <em className={s.going_count}>
                        <IconDotBetween className={s.dot} />
                        <strong>{item.goingCount.toLocaleString()}</strong>대 가는중
                      </em>
                    )}
                    {item.date && <span className={s.date}>{item.date}</span>}
                  </div>
                  <IcArrowRightBold width={20} height={20} color={'gray500'} className={s.ico} />
                </button>
              </InView>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default SearchRecommendFestivalList;
