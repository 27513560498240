import {Fragment} from 'react';
import {ReactComponent as IconDot} from 'resource/images/@tmds_element/ico_dot_between.svg';
import s from 'styles/components/PoiListItemSpecialContent.module.scss';

export type TSpecialColor =
  | 'red500'
  | 'red600'
  | 'orange500'
  | 'green500'
  | 'gray400'
  | 'gray800'
  | 'gray900'
  | 'evBlue500'
  | 'purple500'
  | 'pink600'
  | 'red700';

export type TSpecialDecorateItem = {
  text: string;
  color?: TSpecialColor;
};

type TProps = {
  items: TSpecialDecorateItem[];
};

export const PoiListItemSpecialContent = ({items}: TProps) => {
  return (
    <span className={s.wrap}>
      {items.map((i, idx) => {
        const color = i.color || 'gray700';

        return (
          <Fragment key={idx}>
            {idx > 0 && (
              <i className={s.divider}>
                <IconDot />
              </i>
            )}
            <span data-tmds-color={color} data-color={color} className={s.text}>
              {i.text}
            </span>
          </Fragment>
        );
      })}
    </span>
  );
};
